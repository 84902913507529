import React, {Component} from 'react'
class Sign extends Component {
    constructor(props){
        super(props)
        this.state = {
            un: this.props.un || ''
        }
    }
    toggleClass = (e) => {
        switch(e.target.parentElement.parentElement.id) {
            case 'signup': {
                document.querySelector('#signup').classList.add('hidden')
                return document.querySelector('#signin').classList.remove('hidden')
            }
            case 'signin': {
                document.querySelector('#signin').classList.add('hidden')
                return document.querySelector('#signup').classList.remove('hidden')
            }
            default:{console.log('error')}
        }
    }
    render(){
        let o
        if (!this.props.auth || this.props.auth === 'undefined') {
            o = ''
        } else {
            o = 'hidden'
        }
        return (<div id="signIn" className={o}>
            <h1 style={{color: 'var(--light)', textShadow: '1px 2px 3px #00000040'}}>YOUR ROUTINES</h1>
            <div id="signup" className="SC hidden">
            <div id="signupHead">SIGN UP BELOW</div>
                <form id="signupform" onSubmit={this.props.newUser}>
                    <div id="signupnotif" className="warn"></div>
                    <div className="bind1">
                        <label htmlFor="identifier">Please Enter Email</label>
                        <input type="email" name="identifier"></input>
                    </div>
                    <div className="bind1">
                        <label htmlFor="password">Password</label>
                        <input type="password" minLength="8" name="password"></input>
                    </div>
                    <div className="bind1">
                        <label htmlFor="password2">Confirm Password</label>
                        <input type="password" minLength="8" name="password2"></input>
                    </div>
                    <button type="submit" id="signupsubmit" onClick={(e) => {
                        e.target.innerHTML = '<load-circle></load-circle>'
                        setTimeout(() => {
                            document.querySelector('#signupsubmit').innerHTML = 'SIGN UP'
                        }, 2000)
                    }}>SIGN UP</button>
                </form>
                <div id="toggle">
                    <h5>Have an Account?</h5>
                    <div id="tog" onClick={this.toggleClass}>SIGN IN</div>
                </div>
            </div>
            <div id="signin" className="SC">
                <div id="loginHead">LOG IN</div>
                <form id="loginform" onSubmit={this.props.logIn}>
                    <div id="loginnotif" className="warn">{this.props.authFail ? 'INVALID LOGIN' : ''}</div>
                    <div className="bind1">
                        <label htmlFor="identifier">Please Enter Your Username or Email</label>
                        <input type="text" name="identifier"></input>
                    </div>
                    <div className="bind1">
                        <label htmlFor="password">Password</label>
                        <input type="password" name="password"></input>
                    </div>
                    <button type="submit" onClick={(e) => {
                        e.target.innerHTML = '<load-circle></load-circle>'
                        setTimeout(() => {
                            document.querySelector('#loginsubmit').innerHTML = 'LOG IN'
                        }, 2000)
                    }} id="loginsubmit">LOG IN</button>
                </form>
                <div id="toggle">
                    <h5>Don't Have an Account?</h5>
                    <div id="tog" onClick={this.toggleClass}>SIGN UP</div>
                </div>
            </div>
        </div>)
    }
}
export default Sign