import React, { Component } from 'react';
import './filteredimage'
import './imagefind'
import './imageselector'
import './online'
import ProfilePic from './ProfilePic'
class Messenger extends Component {
    constructor(){
        super()
        this.state = {
            mostRecent: '',
            messaging: '',
            chats: []
        }
    }
    makeChat = (e) => { if (e.target.id !== this.state.messaging) window.app.setState({messaging: e.target.id}) }
    endChat = (e) => {
        if (this.state.messaging !== 'mass') {
            let c = [...this.state.chats]
            for (let i = 0; i < c.length; i++) if (c[i] === this.state.messaging) {let rm = c.splice(i, 1)}
            this.setState({chats: c, messaging: 'mass'})
            window.app.setState({messaging: 'mass'})
        }
    }
    requestMessages = (beat, time) => {
        if (window.app.state.auth && window.app.state.auth !== 'undefined' && window.app.state.userID && window.app.state.profile.firstName) {
            if (window.app.state.messages.length > 0 && window.ws.readyState === WebSocket.OPEN) window.ws.send(JSON.stringify({type: 'event', data: {getMessages: {to: window.app.state.userID, time: time ? time : window.app.state.messages[window.app.state.messages.length - 1].time}, userID: window.app.state.userID, auth: window.app.state.auth}}))
        }
    }
    loadProf = () => {
        let id = this.state.messaging
        if (id !== 'mass') {
            window.app.loadProfile(id)
        }
    }
    toggleClasses = (e) => {
        if (/chattog/i.test(e.target.id)) {
            let o
            let chat = e.target.parentElement.parentElement.querySelector('#chat')
            let mess = e.target.parentElement.parentElement.querySelector('#message')
            let chats = e.target.parentElement.querySelector('#chats')
            let online = e.target.parentElement.parentElement.querySelector('online-users')
            let userchats = e.target.parentElement.parentElement.querySelector('#userchats')
            let ar = [chat, mess, chats, online, userchats]
            for (let i = 0; i < ar.length; i++) {
                if (ar[i].classList.contains('subchat')) {
                    ar[i].classList.remove('subchat')
                    o = true
                } else {
                    ar[i].classList.add('subchat')
                    o = false
                }
            }
            if (o !== true) {
                e.target.innerHTML = 'OPEN CHAT'
                e.target.classList.add('bigtog')
                e.target.parentElement.parentElement.classList.add('subbord')
            } else {
                e.target.innerHTML = 'CLOSE CHAT'
                e.target.classList.remove('bigtog')
                e.target.parentElement.parentElement.classList.remove('subbord')
            }
        } else {
            console.log('BROKEN TOG')
        }
    }
    toggleClasses2 =(e) => {
        let im = e.target?.parentElement?.parentElement?.parentElement?.parentElement?.querySelector('image-selector')
        if (im.classList.contains('hidden')) {im.classList.remove('hidden')} else {
            im.classList.add('hidden')
            if (im.selectedID) {
                e.target.innerHTML = '+1 Image'
            } else {
                e.target.innerHTML = 'Add Image'
            }
        }
    }
    componentDidMount(){
        let a = this.props.messages[this.props.messages.length - 1]
        if (a && this.props.level === true) {
            this.setState({mostRecent: a._id})
            let target = this.state.mostRecent
            if (target) document.querySelector('#e' + target).scrollIntoView({behavior: 'smooth', block: 'end', inline: 'end'})
        }
        this.setState({messaging: this.props.messaging})
        window.chatter = setInterval(this.requestMessages, 5000)
        setTimeout(() => {
            this.requestMessages(null, new Date() - 1)
        }, 1000)
    }
    componentDidUpdate(){
        let a = this.props.messages[this.props.messages.length - 1]
        if (this.props.messaging !== this.state.messaging) {
            if (!this.state.chats?.includes(this.props.messaging)) {
                this.setState({messaging: this.props.messaging, chats: [...this.state.chats, this.props.messaging]})
            } else {
                this.setState({messaging: this.props.messaging})
            }
        }
        if (a && a.to === this.state.messaging && a._id !== this.state.mostRecent) {
            this.setState({mostRecent: a._id})
            let target = this.state.mostRecent
            if (target) document.querySelector('#e' + target).scrollIntoView({behavior: 'smooth', block: 'end', inline: 'end'})
        }
    }
    componentWillUnmount(){
        clearInterval(window.chatter)
    }
    render(){
        this.props.source(this)
        let a = this.props.messages ? [...this.props.messages].filter(mes => mes.to === 'mass').map((message, key) => {
            if (!message) return ''
            if (!message.body || message.body.split('').length < 2) return <div  key={key} id={'e' + message._id}></div>
            let c
            let d
            if (message.from === window.app.state.userID) {
                c = 'right'
                d = 'rightspan'
            } else {
                c = 'message'
                d = ''
            }
            let b = window.app.state.onlineDisp.find(u => u._id === message.from)
            let im
            if (message.attachments && message.attachments.length > 0) {
                let image = window.app.state.images.find(img => img._id === message.attachments[0])
                if (image) {
                    im = <div className="imgBox"><img-f img={image.src} hue={image.hue} 
                    saturate={image.saturate} grayscale={image.grayscale} border={image.border} 
                    brightness={image.brightness} contrast={image.contrast} blur={image.blur} 
                invert={image.invert} opacity={image.opacity} sepia={image.sepia} shadow={'1px 2px 3px #00000050'}></img-f></div>
                } else {
                    im = <div className="imgBox"><image-find img={message.attachments[0]}></image-find></div>
                }
            }
            return <div key={key} id={'e' + message._id} className={c}><span className={d}>From: {b ? b.displayName : c === 'right' ? this.props.un : message.from} @ {window.app.date(message.time)}</span>{im}<p>{message.body}</p></div>
        }) : ''
        let q
        let b
        if (this.props.auth) {
            q = 'Write a new message'
            b = 'buttonBar'
        } else {
            q = 'Please Sign In to Chat'
            b = 'buttonBar hidden'
        }
        let h
        if (this.state.messaging !== 'mass') {
            h = 'global hidden'
        } else {
            h = 'global'
        }
        let r = this.state.chats ? this.state.chats.map((chat, key) => {
            let u
            if (this.state.messaging === chat) {
                u = 'cbut AC'
            } else {
                u = 'cbut'
            }
            return <div className={u} id={chat} onClick={this.makeChat} key={key}>{window.app.state.onlineDisp.find(id => id._id === chat)?.displayName || chat}</div>
        }) : ''
        let pic
        if (this.props.profile) pic = window.app.state.images.find(img => img._id === this.props.profile.profilePic)
        return (<div className="messages">
            <div id="chatMen"><div id="chats">
            {r}
            </div><div id="chattog" onClick={this.toggleClasses}>CLOSE CHAT</div></div>
            <div id="un" onClick={this.loadProf}>{this.state.messaging === 'mass' ? `Chatting as: ${this.props.un ? this.props.un : 'Set Up Your Username'}` : `Chatting with: ${window.app.state.onlineDisp.find(u => u._id === this.state.messaging)?.displayName}`}</div>
            <div id="chat" className={h}>{a}</div>
            <div id="userchats">{this.state.chats ? this.state.chats.map((chat, index) => {
                if (chat === 'mass') return <div key={index}></div>
                let messy
                let requested = (this.props.sentRequests && typeof this.props.sentRequests === 'object') ? this.props.sentRequests : []
                let dirty = (this.props.friendRequests && typeof this.props.friendRequests === 'object') ? this.props.friendRequests : []
                let greek = (this.props.friendList && typeof this.props.friendList === 'object') ? this.props.friendList : []
                if (this.props.friendList && this.props.friendList.map(u => u.user).includes(chat)) {
                    let m = this.props.messages.filter(mes => ((mes.to === chat || mes.to === window.app.state.userID) && (mes.from === chat && mes.from === window.app.state.userID)))
                    if (m && m.length > 0) {
                        messy = m.map((message, index) => {
                            if (!message) return ''
                            if (!message.body || message.body.split('').length < 2) return <div  key={index} id={'e' + message._id}></div>
                            let c
                            let d
                            if (message.from === window.app.state.userID) {
                                c = 'right'
                                d = 'rightspan'
                            } else {
                                c = 'message'
                                d = ''
                            }
                            let b = window.app.state.onlineDisp.find(u => u._id === message.from)
                            let im
                            if (message.attachments && message.attachments.length > 0) {
                                let image = window.app.state.images.find(img => img._id === message.attachments[0])
                                if (image) {
                                    im = <div className="imgBox"><img-f img={image.src} hue={image.hue} 
                                    saturate={image.saturate} grayscale={image.grayscale} border={image.border} 
                                    brightness={image.brightness} contrast={image.contrast} blur={image.blur} 
                                invert={image.invert} opacity={image.opacity} sepia={image.sepia} shadow={'1px 2px 3px #00000050'}></img-f></div>
                                } else {
                                    im = <div className="imgBox"><image-find img={message.attachments[0]}></image-find></div>
                                }
                            }
                            return <div key={index} id={'e' + message._id} className={c}><span className={d}>From: {b ? b.displayName : c === 'right' ? this.props.un : message.from} @ {window.app.date(message.time)}</span>{im}<p>{message.body}</p></div>
                        })
                    } else {
                        messy = 'NO MESSAGES YET'
                    }
                } else if (requested && requested.length > 0 && requested.includes(chat)) {
                    messy = <div style={{maxWidth: '100%', wordBreak: 'break-word', padding: '5px', borderRadius: '12px', boxShadow: '1px 1px 3px var(--prim)', backgroundColor: 'var(--light)'}}><div className="warn">FRIEND REQUEST SENT</div><button onClick={this.loadProf}>CANCEL REQUEST</button></div>
                } else if (dirty && dirty.length > 0 && dirty.includes(chat)) {
                    messy = <div style={{maxWidth: '100%', wordBreak: 'break-word', padding: '5px', borderRadius: '12px', boxShadow: '1px 1px 3px var(--prim)', backgroundColor: 'var(--light)'}}><div className="warn">{window.app.state.displayNames.find(u => u.user === chat)?.displayName} ADDED YOU</div><button onClick={this.loadProf}>ACCEPT REQUEST</button></div>
                } else if (greek && greek.length > 0 && greek.includes(chat)) {
                    let m = []
                    let mes = [...this.props.messages]
                    for (let i = 0; i < mes.length; i++) {
                        if (mes[i].from === chat) {
                            if (mes[i].to === window.app.state.userID) m.push(mes[i])
                        } else if (mes[i].to === chat) {
                            if (mes[i].from === window.app.state.userID) m.push(mes[i])
                        }
                    }
                    if (m && m.length > 0) {
                        messy = m.map((message, index) => {
                            if (!message) return ''
                            if (!message.body || message.body.split('').length < 2) return <div  key={index} id={'e' + message._id}></div>
                            let c
                            let d
                            if (message.from === window.app.state.userID) {
                                c = 'right'
                                d = 'rightspan'
                            } else {
                                c = 'message'
                                d = ''
                            }
                            let b = window.app.state.onlineDisp.find(u => u._id === message.from)
                            let im
                            if (message.attachments && message.attachments.length > 0) {
                                let image = window.app.state.images.find(img => img._id === message.attachments[0])
                                if (image) {
                                    im = <div className="imgBox"><img-f img={image.src} hue={image.hue} 
                                    saturate={image.saturate} grayscale={image.grayscale} border={image.border} 
                                    brightness={image.brightness} contrast={image.contrast} blur={image.blur} 
                                invert={image.invert} opacity={image.opacity} sepia={image.sepia} shadow={'1px 2px 3px #00000050'}></img-f></div>
                                } else {
                                    im = <div className="imgBox"><image-find img={message.attachments[0]}></image-find></div>
                                }
                            }
                            return <div key={index} id={'e' + message._id} className={c}><span className={d}>From: {b ? b.displayName : c === 'right' ? this.props.un : message.from} @ {window.app.date(message.time)}</span>{im}<p>{message.body}</p></div>
                        })
                    } else {
                        messy = 'NO MESSAGES YET'
                    }
                } else{
                    messy = <div style={{maxWidth: '100%', wordBreak: 'break-word', padding: '5px', borderRadius: '12px', boxShadow: '1px 1px 3px var(--prim)', backgroundColor: 'var(--light)'}}><div className="warn">YOU MUST BE FRIENDS TO CHAT</div><button onClick={this.loadProf}>CLICK HERE TO ADD FRIEND</button></div>
                }
                let g
                if (this.state.messaging === chat) {
                    g = 'userchat'
                } else {
                    g = 'hidden'
                }
            return <div key={index} id="chat" className={g}>{messy}</div>
            }) : ''}</div>
            <online-users></online-users>
            <image-selector id="chatimgselect" class="hidden"></image-selector>
            <div id="message">
                <div id="CUC" className={this.state.messaging === 'mass' ? 'hidden' : ''} onClick={this.endChat}>END CHAT</div>
                <form id="im-form" onSubmit={this.props.newMessage}>
                    <div id="profilePic"><ProfilePic img={pic ? pic : window.app.api + '/static/pp.jpeg'}></ProfilePic></div>
                    <h2>{q}</h2>
                    <div className="warn"></div>
                    <input name="to" defaultValue={this.state.messaging} type="hidden"></input>
                    <textarea minLength={1} placeholder="hello" name="message"></textarea>
                    <div className={b}><button type="submit">SEND</button><div className="button" id="AAButton" onClick={this.toggleClasses2}>ADD IMAGE</div></div>
                </form>
            </div>
        </div>)
    }
}
export default Messenger