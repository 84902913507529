class ImageSelector extends HTMLElement {
    constructor(){
        super()
        this.shadow = this.attachShadow({mode:'open'})
    }
    loadLiteral = (cont, data, cb) => data.forEach(d => cont.innerHTML += cb(d))
    imgTemp = (data) => {
        return `<div id="${data._id}" class="image"><img-f img="${data.src}" hue="${data.hue}" saturate="${data.saturate}" grayscale="${data.grayscale}" border="${data.border}" brightness="${data.brightness}" contrast="${data.contrast}" blur="${data.blur}" invert="${data.invert}" opacity="${data.opacity}" sepia="${data.sepia}" shadow="${data.shadow}"></img-f></div>`
    }
    render(){
        this.shadow.innerHTML = `<style>
            #bCont {
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                max-width: 100%;
                overflow: hidden;
                margin: 0;
                padding: 0;
                width: 100%;
            }
            #cont {
                max-width: 100%;
                max-height: 300px;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                overflow-x: hidden;
                align-items: center;
                overflow-y: scroll;
                background-color: var(--light);
                grid-gap: 1px;
            }
            .image {
                border: 2px solid var(--dark);
                max-width: 100%;
                transition: 230ms;
                cursor: pointer;
            }
            .selected {
                border: 6px double var(--prim);
            }
            button {
                font-size: 2.2rem;
                font-weight: 800;
                color: var(--light);
                background-color: var(--dark);
                border-radius: 8px;
                width: 4rem;
                height: 4rem;
                justify-self: center;
            }
            #close {
                width: 100%;
                max-width: 100%;
                margin: 0;
                margin-bottom: 5px;
                padding: 5px 0px;
                font-size: .8rem;
                background-color: var(--prim);
                color: var(--light);
                cursor: pointer;
            }
        </style>
        <div id="bCont"><div id="close">${this.selectedID ? 'ADD SELECTED IMAGE' : 'CLOSE IMAGE SELECT'}</div><div id="cont"></div></div>`
        let cont = this.shadow.querySelector('#cont')
        this.close = this.shadow.querySelector('#close')
        this.images = [...window.app.state.images].filter(img => img.owner === window.app.state.userID)
        this.loadLiteral(cont, this.images, this.imgTemp)
        Array.from(this.shadow.querySelectorAll('img-f')).forEach(img => {
            img.addEventListener('click', (e) => {
                if (e.target.parentElement.id === this.selectedID) {
                    e.target.parentElement.classList.remove('selected')
                    this.tog.innerHTML = 'Add Image'
                    this.selectedID = ''
                } else {
                    if (this.shadow.querySelector('.selected')) this.shadow.querySelector('.selected').classList.remove('selected')
                    e.target.parentElement.classList.add('selected')
                    this.selectedID = e.target.parentElement.id
                }
                this.close.innerHTML = this.selectedID ? 'ADD SELECTED IMAGE' : 'CLOSE IMAGE SELECT'
            })
            if (img.parentElement.id === this.selectedID) {
                img.parentElement.classList.add('selected')
            }
        })
        let a = document.createElement('button')
        cont.append(a)
        a.addEventListener('click', () => window.app.uploadImage())
        a.innerHTML = '+'
        this.close.addEventListener('click', () => {
            if (this.selectedID && this.selectedID !== 'undefined') this.tog.innerHTML = '+1 Image'
            this.classList.add('hidden')
        })
    }
    checkImg(){
        let o = [...window.app.state.images].filter(img => img.owner === window.app.state.userID)
        if (o.length !== this.images.length) this.render()
    }
    connectedCallback(){
        this.time = setInterval(() => {this.checkImg()}, 2000)
        this.tog = this.parentElement.querySelector('#AAButton')
        this.render()
        
    }
    disconnectedCallback(){
        clearInterval(this.time)
    }
    static get observedAttributes(){
        return ['select']
    }
    attributeChangedCallback(n, o, i) {
        if (n === 'select' && i !== this.selectedID) {
            this.selectedID = i
            this.render()
        }
    }
}
customElements.define('image-selector', ImageSelector)