class FilteredImage extends HTMLElement {
    constructor(){
        super()
        this.shadow = this.attachShadow({mode:'open'})
    }
    filter(){
        let o = 0
        let filter = 'filter: '
        if (this.hasAttribute(['brightness']) && this.getAttribute(['brightness']) !== 'undefined') {
            filter += `brightness(${this.getAttribute(['brightness'])}%) `
            o++
        }
        if (this.hasAttribute(['contrast']) && this.getAttribute(['contrast']) !== 'undefined') {
            filter += `contrast(${this.getAttribute(['contrast'])}%) `
            o++
        }
        if (this.hasAttribute(['hue']) && this.getAttribute(['hue']) !== 'undefined') {
            filter += `hue-rotate(${this.getAttribute('hue')}deg) `
            o++
        }
        if (this.hasAttribute(['grayscale']) && this.getAttribute(['grayscale']) !== 'undefined') {
            filter += `grayscale(${this.getAttribute(['grayscale'])}%) `
            o++
        }
        if (this.hasAttribute(['blur']) && this.getAttribute(['blur']) !== 'undefined') {
            filter += `blur(${this.getAttribute(['blur'])}px) `
            o++
        }
        if (this.hasAttribute(['sepia']) && this.getAttribute(['sepia']) !== 'undefined') {
            filter += `sepia(${this.getAttribute(['sepia'])}%) `
            o++
        }
        if (this.hasAttribute(['invert']) && this.getAttribute(['invert']) !== 'undefined') {
            filter += `invert(${this.getAttribute(['invert'])}%) `
            o++
        }
        if (this.hasAttribute(['saturate']) && this.getAttribute(['saturate']) !== 'undefined') {
            filter += `saturate(${this.getAttribute(['saturate'])}%) `
            o++
        }
        if (o > 0) {
            filter += '; '
        } else {
            filter = ''
        }
        if (this.hasAttribute(['border']) && this.getAttribute(['border']) !== 'undefined') {
            filter += `border-radius: ${this.getAttribute(['border'])}px; `
        }
        if (this.hasAttribute(['shadow']) && this.getAttribute(['shadow']) !== 'undefined') {
            filter += `box-shadow: ${this.getAttribute(['shadow'])}; `
        }
        if (this.hasAttribute(['opacity']) && this.getAttribute(['opacity']) !== 'undefined') {
            filter += `opacity: ${this.getAttribute(['opacity'])};`
        }
        return filter
    }
    async render(){
        this.shadow.innerHTML = '<load-circle></load-circle>'
        this.img = document.createElement('img')
        this.img.src = this.getAttribute(['img'])
        this.img.style = this.filter()
        await new Promise(res => this.img.onload = res)
        let aspect = this.img.naturalWidth/this.img.naturalHeight
        if (aspect < .85) {
            this.w = '50%'
            this.wm = '30%'
        } else {
            this.w = '100%'
            this.wm = '80%'
        }
        this.shadow.innerHTML = `<style>
            img {
                max-width: ${this.w};
                margin: 0;
                padding: 0;
                object-fit: contain;
            }
            @media screen and (max-width: 650px) {
                img {
                    max-width: ${this.wm}%;
                }
            }
        </style>`
        this.shadow.append(this.img)
        if (this.shadow.querySelector('load-circle')) this.shadow.querySelector('load-circle').remove()
    }
    connectedCallback(){
        if (this.hasAttribute(['img'])) {
            this.render()
        }
    }
    static get observedAttributes(){
        return ['img', 'brightness', 'contrast', 'hue', 'grayscale', 'opacity', 'shadow', 'border', 'saturate', 'invert', 'blur', 'sepia']
    }
    attributeChangedCallback(name){
        if (name === 'img') {
            this.render()
        } else if (['brightness', 'contrast', 'hue', 'grayscale', 'opacity', 'shadow', 'border', 'saturate', 'invert', 'blur', 'sepia'].includes(name)) {
            if (this.img) this.img.style = this.filter()
        }
    }
}
customElements.define('img-f', FilteredImage)