import React, {Component} from 'react'
import ProfilePic from './ProfilePic'
import NewImage from './NewImage'
class ProfileCreation extends Component {
    render(){
        let profile = this.props.profile
        let image = window.app.state.images.find(img => img._id === profile.profilePic)
        return (<div id="profCreate">
            <div id="ProfClose" className="newtodo hidden" onClick={this.props.close}>CLOSE</div>
            <div className="b1"><div id="PPreview"><ProfilePic img={image ? image : null}></ProfilePic ></div><h4>Profile Picture</h4><NewImage cP={true} newImage={window.app.newPImage}></NewImage></div>
            <form id="form1" onSubmit={this.props.profilePhase1}>
                <h4>The Basics</h4>
                <div className="warn"></div>
                <div className="b2"><label htmlFor="firstName">First Name</label><input name="firstName" type="text" minLength={2}></input></div>
                <div className="b2"><label htmlFor="lastName">Last Name</label><input name="lastName" type="text" minLength={2}></input></div>
                <div className="b2"><label htmlFor="displayName">Display Name</label><input name="displayName" type="text" minLength={1}></input></div>
                <button type="submit">CONFIRM</button>
            </form>
        </div>)
    }
}

export default ProfileCreation