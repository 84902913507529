import React, {Component} from 'react'
import './imagefind'
import './search.css'
class SearchIcon extends HTMLElement {
constructor(){
super()
this.shadow = this.attachShadow({mode:'open'})
}
connectedCallback(){
this.shadow.innerHTML = `<style>
  #cont {
    max-width: 100%;
  }
  svg {
    width: 50px;
    height: 50px;
    max-width: 100%;
  }
</style>
<div id="cont"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 57.97">
<defs>
  <style>
    .cls-1 {
      fill: #fff;
      stroke: #000;
      stroke-miterlimit: 10;
    }
  </style>
</defs>
<g id="Layer_2" data-name="Layer 2">
<g id="Capa_1" data-name="Capa 1">
  <path class="cls-1" d="M54.66,52.39,41.1,38.29a23,23,0,1,0-4.42,4.05L50.34,56.55a3,3,0,1,0,4.32-4.16ZM23.5,6.5a17,17,0,1,1-17,17A17,17,0,0,1,23.5,6.5Z"/>
</g>
</g>
</svg></div>`
}
}
customElements.define('search-icon', SearchIcon)
class Search extends Component {
    constructor(){
        super()
        this.state = {
            searchTerm: '',
            results: [],
            viewing: {}
        }
    }
    componentDidUpdate(){
      if (!window.compareObjects({...this.state.viewing}, {...this.props.viewing})) this.setState({viewing: this.props.viewing})
    }
    componentDidMount(){
      this.setState({viewing: this.props.viewing})
    }
    loadProfile = e => window.app.loadProfile((e.target?.id ? e.target.id : e.target?.parentElement?.id ? e.target.parentElement.id : e.target?.parentElement?.parentElement?.id ? e.target.parentElement.parentElement.id : null))
    result = () => {
      if (!this.state.results || this.state.results.length < 1) return ''
      let list = this.props.online.map(z => z._id)
      return this.state.results.map((u, i) => {
        if (list.includes(u.user)) {
          return (<div className="result onlineNOW" id={u._id} key={i} onClick={this.loadProfile}>
            <image-find img={u.image}></image-find>
            <span>{u.displayName}</span>
          </div>)
        } else {
          return (<div className="result" id={u._id} key={i} onClick={this.loadProfile}>
            <image-find img={u.image}></image-find>
            <span>{u.displayName}</span>
          </div>)
        }

      })
    }
    loadLiteral(elem, data, func) {
      for (let i = 0; i < data.length; i++) {
        let a = document.createElement('li')
        elem.append(a)
        a.innerHTML += func(data[i])
        a.addEventListener('click', this.loadProfile)
      }
    }
    searched = (term, array) => {
      let a = []
      let reg = new RegExp(term, 'i')
      for (let i = 0; i < array.length; i++) {
        if (reg.test(array[i].displayName)) a.push(array[i])
      }
      return a
    }
    searchResult = data => {
      return `<div class="searched" id="${data._id}"><image-find img="${data.image}"></image-find><h3>${data.displayName}</h3></div>`
    }
    handleSearch = (e) => {
        let t = e.target.value
        this.setState({searchTerm: t})
        let a = document.querySelector('#relevance')
        a.innerHTML = ''
        if (t.length < 1) return this.setState({results: []})
        let c = [...this.props.searchList].filter((u) => {
          if (!u.displayName || u.displayName === 'undefined' || u.user === this.props.un) return false
          return true
        })
        let r = this.searched(t, c)
        this.loadLiteral(a, r, this.searchResult)
        this.setState({results: r})
    }
    startSearch = () => {
        window.app.setState({showSearch: true})
    }
    closeSearch = () => {
        window.app.setState({showSearch: false})
    }
    render(){
        let h
        if (this.props.showSearch) {
            h = ''
        } else {
            h = 'hidden'
        }
        let g
        if (!this.state.searchTerm) {
          g = ''
        } else {
          g =  ' hidden'
        }
        let online = this.props.online ? this.props.online : []
        return (<div className={(h === 'hidden' ? 'closedSearch' : 'openSearch') + (this.props.un ? '' : ' hidden')} style={this.state.viewing?.firstName ? {zIndex: '0'} : {}}>
            <div id="closeSearch" className={h} onClick={this.closeSearch} style={{left: '5%!important', right: 'auto!important'}}>{'<'}</div>
            <div id="searchbox"><div id="searchIcon" onClick={this.startSearch}><search-icon></search-icon></div><div className={'b1 ' + h}><input type="text" name="search" value={this.state.searchTerm} onChange={this.handleSearch}></input><ul id="relevance"></ul></div></div>
            <div id="Search">
              <div id="onlineNow" className={'b1 s1 ' + h + ' ' + (online.length > 0 ? '' : 'hidden') + g}>
                <h1 className="searchHead">Online Now</h1>
                  <div className="resultSlide">
                    {online.map((user, index) => {
                      let o = [...this.props.searchList].filter(u => {return u.user !== this.props.un && user._id === u.user})
                      if (o[0]) {
                        let a = o[0]
                        return (<div key={index} className="searchResult onlineNOW" style={{border: '2px solid var(--prim)', boxShadow: '1px 2px 3px var(--dark)'}} id={a._id} onClick={this.loadProfile}>
                          <image-find img={a.image}></image-find>
                          <span>{a.displayName}</span>
                        </div>)
                      } else {
                        return ''
                      }
                    })}
                  </div>
                </div>
              <div id="mostActive" className={'b1 s1 ' + h + g}>
                <h1 className="searchHead">Popular</h1>
                <div className="resultSlide">
                  <span>{'Nobody is popular yet.'}</span>
                </div>
              </div>
              <div id="generated" className={'b1 s1 ' + h + (g === ' hidden' ? '' : 'hidden')}>
                  <h1 className="searchHead">Results for {this.state.searchTerm}</h1>
                  <div className="b2 wrap">
                    {this.result()}
                  </div>
              </div>
            </div> 
        </div>)
    }
}
export default Search