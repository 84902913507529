import React, {Component} from 'react'

class Nav extends Component {
    toggleClasses(e){
        if (/navtog/.test(e.target.id)) {
            let list = e.target.parentElement.querySelector('#navlist')
            if (list.classList.contains('hidden')) {
                list.classList.remove('hidden')
                e.target.classList.remove('fulltog')
                e.target.innerHTML = 'CLOSE NAV'
                e.target.parentElement.classList.remove('closednav')
            } else {
                list.classList.add('hidden')
                e.target.innerHTML = 'OPEN NAV'
                e.target.classList.add('fulltog')
                e.target.parentElement.classList.add('closednav')
            }
        } else {
            console.log('Broken Tog')
        }
    }
    render(){
        let o
        if (this.props.auth && this.props.auth !== 'undefined') {
            o = ''
        } else {
            o = ' hidden'
        }
        return (<div id="nav" className={'closednav' + o}>
            <div id="navtog" className="button fulltog" onClick={this.toggleClasses}>OPEN NAV</div>
            <ul id="navlist" className="hidden">
            <li onClick={() => {window.app.setState({showProfile: true})}}>My Profile</li>
                <li onClick={this.props.openImages}>My Images</li>
                <li onClick={this.props.search}>Discover</li>
                <li onClick={this.props.signOut}>Sign Out</li>
            </ul>
        </div>)
    }
}

export default Nav