class Online extends HTMLElement {
    constructor(){
        super()
        this.shadow = this.attachShadow({mode:'open'})
    }
    loadLiteral = (cont, data, func) => data.forEach(point => cont.innerHTML += func(point))
    onlineUser = data => {
        try {
            if (!data || data === 'undefined') return ''
            if (data && (data._id === window.app.state.userID || window.chat?.state?.chats?.includes(data._id))) return ''
            return `<li id="${data._id}">${data.displayName}</li>`
        } catch(e) {
            return ''
        }
    }
    addClicks(array) {
        if (array && typeof array === 'object') array.forEach(elem => elem.addEventListener('click', (e) => {
            if (!window.app.state.auth || window.app.state.auth === 'undefined') return e.target.style.cursor = 'not-allowed'
            if(e.target.classList.contains('selected')) {
                e.target.classList.remove('selected')
            } else {
                e.target.classList.add('selected')
                window.app.setState({messaging: e.target.id})
            }
        }))
    }
    loadOnline = () => {
        let cont = this.shadow.querySelector('ul')
        if (!cont || !this.displays || !this.onlineUser) return
        cont.innerHTML = ''
        cont.style.gridTemplateColumns = this.displayColumns(this.displays.length)
        this.loadLiteral(cont, this.displays, this.onlineUser)
        this.addClicks(Array.from(this.shadow.querySelectorAll('li')))
    }
    findOnline = array => {
        window.app.sendData('/displays', array).then(result => window.app.validate(result)).then(result => {
            window.app.setState({onlineDisp: result.displays})
            this.displays = result.displays
            this.loadOnline()
        }).catch(e => {
            console.log(e)
        })
    }
    displayColumns(num){
        let it = ''
        let num2 = num - 1
        for (let i = 0; i < num; i++) {
            if (i < num2) it += '1fr '
            if (i === num2) it += '1fr'
        }
        return it
    }
    render(){
        this.shadow.innerHTML = `<style>
        #C {
            position: relative;
            margin: 0;
            padding: 0;
            max-width: 100%;
            width: 100%;
            overflow: hidden;
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: var(--dark);
            border-radius: 12px;
            box-shadow: 1px 2px 3px #00000040;
        }
        span {
            color: var(--prim);
            padding: 5px;
            max-width: 100%;
            font-size: .8rem;
            font-weight: 800;
            cursor: default;
        }
        .selected {
            border: 3px solid var(--prim);
            background-color: var(--dark);
            animation: spin 1s ease-in-out 0 infinite running normal;
        }
        @keyframes spin {
            from: {
                transform: rotate(0deg);
            }
            to: {
                transform: rotate(360deg);
            }
        }
        load-circle {
            max-width: 10%;
        }
        ul {
            list-style: none;
            display: grid;
            grid-gap: 8px;
            background-color: var(--dark);
            align-items: center;
            width: 100%;
            max-width: 100%;
            overflow-y: hidden;
            overflow-x: scroll;
            margin: 0;
            padding: 0;
        }
        ul::-webkit-scrollbar {
            display: none;
        }
        li {
            max-width: 100%;
            word-break: break-all;
            font-size: 1rem;
            color: var(--light);
            background-color: var(--prim);
            text-shadow: 1px 2px 3px #00000040;
            padding: 5px;
            font-weight: 800;
            cursor: pointer;
        }
        </style>
        <div id="C">
            <span>${this.online} PEOPLE ONLINE! ACTIVE USERS:</span>
            <ul><span>Searching for users...</span><load-circle></load-circle></ul>
        </div>`
        setTimeout(() => {
            let c = this.shadow.querySelector('load-circle')
            if (c) c.remove()
            this.shadow.querySelector('span').innerHTML = `${this.online} PEOPLE ONLINE! ACTIVE USERS:`
        }, 1000)
    }
    connectedCallback(){
        window.online = setInterval(() => {
            let o = [...this.users]
            let z = this.online
            this.online = window.app.state.onlineUsers.length
            this.users = window.app.state.onlineUsers
            for (let i = 0; i < o.length; i++) {
                if (this.users[i]?._id !== o[i]?._id) {
                    console.log('NEW USER JOINED')
                    this.findOnline(this.users.map(u => u._id))
                    i = o.length
                } else if (i === (o.length - 1)) {
                    this.loadOnline()
                }
            }
            if (this.online !== z) this.shadow.querySelector('span').innerHTML = `${this.online} PEOPLE ONLINE! ACTIVE USERS:`
        }, 1000)
        this.online = window.app.state.onlineUsers.length
        this.users = window.app.state.onlineUsers
        this.render()
    }
    disconnectedCallback(){
        clearInterval(window.online)
    }
}
customElements.define('online-users', Online)