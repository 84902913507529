import React, {Component} from 'react'
class NewImage extends Component {
    render(){
        console.log('Image form data', this.props.auth, this.props.userID)
        let c
        if (!this.props.cP) {
            c = "imageForm"
        } else {
            c = 'CPUF'
        }
      return (<form className={c} encType="multipart/form-data" onSubmit={this.props.newImage}>
          <div className="warn"></div>
        <label htmlFor="image">Upload a New Image</label><br/>
        <input style={{maxWidth: '80%', margin: '10px 0px', padding: '0'}} type="file" name="image" onChange={function(e){
            if (e.target.files[0].size > 1048576*6) {
                e.target.parentElement.firstElementChild.innerHTML = 'Oops... File is too big! (max-size: 6MB)'
                e.target.value = ''
            } else {
                e.target.parentElement.firstElementChild.innerHTML = 'UPLOAD'
            }
        }} accept="image/jpeg, image/png"></input>
        <button type="submit">UPLOAD</button><div className="newtodo" id="closenewimage" onClick={this.props.close}>CLOSE</div>
        </form>)
    }
  }
  export default NewImage