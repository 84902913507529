class ImageFind extends HTMLElement {
    render(){
        let data = this.image
        if (!data) return
        this.innerHTML = `<img-f img="${data.src}" hue="${data.hue}" saturate="${data.saturate}" grayscale="${data.grayscale}" border="${data.border}" brightness="${data.brightness}" contrast="${data.contrast}" blur="${data.blur}" invert="${data.invert}" opacity="${data.opacity}" sepia="${data.sepia}" shadow="${data.shadow}"></img-f>`
    }
    connectedCallback(){
        this.innerHTML = '<load-circle></load-circle>'
        if (this.hasAttribute(['img'])) {
            let attr = this.getAttribute(['img'])
            let im = window.app.state.images.filter(img => img._id === attr)
            if (!im[0] || !im[0].src) {
                console.log('ATTEMPTING IMAGE SEARCH FOR:', attr)
                window.app.imageFind(attr).then(result => {
                    this.image = result
                    this.render()
                }).catch(e => {console.log(e); this.innerHTML = 'FAILED TO LOAD IMAGE'})
            } else {
                this.image = im[0]
                this.render()
            }
        } else {
            this.innerHTML = ''
        }
    }
    static get observedAttributes(){
        return ['img']
    }
    attributeChangedCallback(name, o, n){
        if (['img'].includes(name)) this.connectedCallback()
    }
}
customElements.define('image-find', ImageFind)