import React, {Component} from 'react'
class ProfilePic extends Component {
    render(){
        let image = this.props.img ? this.props.img : {src: window.app.api + '/static/pp.jpeg'}
        return (<img-f img={image.src} hue={image.hue} 
            saturate={image.saturate} grayscale={image.grayscale} border={image.border > 0 ? image.border : 100} 
            brightness={image.brightness} contrast={image.contrast} blur={image.blur} 
        invert={image.invert} opacity={1} sepia={image.sepia} shadow={'1px 2px 3px #00000050'}></img-f>)
    }
}
export default ProfilePic