import React, {Component} from 'react'
import './profile.css'
import ProfilePic from './ProfilePic'
import './imageselector'
import './imagefind'
class Profile extends Component {
    constructor(){
        super()
        this.state = {
            firstName: '',
            lastName: '',
            displayName: '',
            bio: '',
            pHead: '',
            pBody: '',
            editing: '',
            pImage: null
        }
    }
    componentDidMount(){
        let a = document.querySelector('#cp2b')
        if (a) a.addEventListener('click', this.openCreatePost)
        if (this.props.profile) this.setState({firstName: this.props.profile?.firstName ? this.props.profile.firstName : '', lastName: this.props.profile?.lastName ? this.props.profile.lastName : '', displayName: this.props.profile?.displayName ? this.props.profile.displayName : '', bio: this.props.profile?.bio ? this.props.profile.bio : ''})
    }
    componentDidUpdate(){
        if (this.props.profile) {
            if (!this.state.firstName && this.state.firstName !== this.props.profile.firstName) this.setState({firstName: this.props.profile.firstName})
            if (!this.state.lastName && this.state.lastName !== this.props.profile.lastName) this.setState({lastName: this.props.profile.lastName})
            if (!this.state.displayName && this.state.displayName !== this.props.profile.displayName) this.setState({displayName: this.props.profile.displayName})
            if (!this.state.bio && this.state.bio !== this.props.profile.bio) this.setState({bio: this.props.profile.bio})
        }
    }
    createPost(e){
        e.preventDefault()
        const form = e.target
        const warn = form.querySelector('.warn')
        const warning = e => {warn.innerHTML = e; form.querySelector('button').innerHTML = 'Submit'}
        let body = form['body'].value
        let header = form['header'].value
        let images = form.parentElement.parentElement.querySelector('image-selector').selectedID
        if (!body) return warning('No Body Entered')
        let post = {
            body: body,
            header: header ? header : '',
            images: images ? [images] : []
        }
        let auth = window.app.state.auth
        let user = window.app.state.userID
        let that = new XMLHttpRequest()
        that.onerror = (e) => {return warning(e)}
        that.onabort = (e) => {return warning(e)}
        that.onreadystatechange = () => {
            if (that.readyState === 4) {
                let data = JSON.parse(that.responseText)
                if (data.error === false) {
                    form.reset()
                    form.querySelector('button').innerHTML = 'Submit'
                    form.parentElement.parentElement.parentElement.querySelector('#cp2b').click()
                    window.app.setState({profile: data.profile, posts: [...window.app.state.posts, data.post]})
                } else {
                    return warning('FAILED TO POST', data.message)
                }
            }
        }
        that.open('POST', window.app.api + '/newpost', true)
        that.setRequestHeader('Content-Type', 'application/json')
        that.setRequestHeader('Authorization', `Bearer ${auth}`)
        that.send(JSON.stringify({user: user, post: post}))
    }
    closeEditPost(id){
        let b = document.querySelector('#e' + id)
        if (b) {
            let imageSel = b.querySelector('image-selector')
            if (imageSel && !imageSel.classList.contains('hidden')) imageSel.classList.add('hidden')
            b.querySelector('#editPostForm').classList.add('hidden')
            b.querySelector('#postContents').classList.remove('hidden')
            b.querySelector('#userOpt').classList.remove('hidden')
        } else {
            console.log('FAILED TO LOCATE DIV')
        }
    }
    startEditPost = (e) => {
        console.log(e.target.parentElement.parentElement.parentElement.id)
        let id = e.target.parentElement.parentElement.parentElement.id
        let fix = id.split('').map((l, i) => i !== 0 ? l : '').join('')
        if (this.state.editing) {
            this.closeEditPost(this.state.editing)
        }
        let post = window.app.state.posts.find(p => p._id === fix)
        if (post) {
            this.setState({editing: fix, pHead: post.header, pBody: post.body, pImage: post.image ? post.image : null})
            e.target.parentElement.parentElement.querySelector('#editPostForm').classList.remove('hidden')
            e.target.parentElement.parentElement.parentElement.querySelector('#postContents').classList.add('hidden')
            e.target.parentElement.classList.add('hidden')
        }
    }
    editPost = (e) => {
        e.preventDefault()
        const form = e.target
        const warn = form.querySelector('.warn')
        const warning = e => {warn.innerHTML = e; form.querySelector('button').innerHTML = 'Submit'}
        let body = form['body'].value
        let header = form['header'].value
        let images = form.parentElement.querySelector('image-selector').selectedID
        if (!body) return warning('No Body Entered')
        let post = {
            _id: form.parentElement.parentElement.id.split('').map((l, i) => i !== 0 ? l : '').join(''),
            body: body,
            header: header ? header : '',
            images: images ? [images] : []
        }
        let auth = window.app.state.auth
        let user = window.app.state.userID
        let that = new XMLHttpRequest()
        that.onerror = (e) => {return warning(e)}
        that.onabort = (e) => {return warning(e)}
        that.onreadystatechange = () => {
            if (that.readyState === 4) {
                let data = JSON.parse(that.responseText)
                if (data.error === false) {
                    form.reset()
                    form.querySelector('button').innerHTML = 'Submit'
                    let changed = [...window.app.state.posts].map(item => {
                        if (item._id === data.post._id) {
                            item = data.post
                        }
                        return item
                    })
                    window.app.setState({profile: {...window.app.state.profile}, posts: changed})
                    this.closeEditPost(this.state.editing)
                } else {
                    return warning('FAILED TO POST', data.message)
                }
            }
        }
        that.open('POST', window.app.api + '/updatepost', true)
        that.setRequestHeader('Content-Type', 'application/json')
        that.setRequestHeader('Authorization', `Bearer ${auth}`)
        that.send(JSON.stringify({user: user, post: post}))
    }
    deletePost = (e) => {
        const par = e.target.parentElement
        const post = e.target.parentElement.parentElement.parentElement.id
        if (post) window.app.sendData('/deletepost', {_id: post.split('').map((l, i) => i !== 0 ? l : '').join('')}, window.app.state.auth).then(result => {
            let data = result
            par.classList.add('hidden')
            if (data.error === false) {
                let a = [...window.app.state.posts].filter(posted => posted._id !== post._id)
                window.app.setState({posts: a, profile: data.profile})
                this.render()
            } else {
                console.log('failed to delete post')
            }
        }).catch(er => {
            console.log(er)
            par.classList.add('hidden')
        })
    }
    delSure(e){
        e.target.parentElement.parentElement.querySelector('#delSure').classList.remove('hidden')
    }   
    editAbout(e){
        let one = e.target.parentElement
        let two = one.parentElement
        one.classList.add('hidden')
        two.querySelector('#uProfInfo').classList.remove('hidden')
        console.log(e.target.id)
    }
    closeEAbout(e){
        let one = e.target.parentElement.parentElement.parentElement
        let two = one.parentElement
        console.log(one, two)
        one.classList.add('hidden')
        two.querySelector('#profInfo').classList.remove('hidden')
    }
    changePic(){
        window.app.setState({showImages: true})
    }
    changeHandle1 = (e) => {
        console.log(e.target.id)
        let a = e.target.id
        switch(a) {
            case 'firstName': {
                return this.setState({firstName: e.target.value})
            }
            case 'lastName': {
                return this.setState({lastName: e.target.value})
            }
            case 'displayName': {
                return this.setState({displayName: e.target.value})
            }
            default: {
                this.setState({bio: e.target.value})
            }
        }
    }
    changeHandle2 = (e) => {
        switch(e.target.name) {
            case 'header': {
                return this.setState({pHead: e.target.value})
            }
            case 'body': {
                return this.setState({pBody: e.target.value})
            }
            default: {
                console.log(e.target.name, 'failing input at profile')
            }
        }
    }
    changeHandle3 = (e) => {
        this.setState({pImage: e.target.id})
    }
    profileUpdate1 = (e) => {
        e.preventDefault()
        const form = e.target
        const warn = e.target.querySelector('.warn')
        const warning = (e) => warn.innerHTML = e
        let bio = form['bio'].value
        let fn = form['firstName'].value
        let ln = form['lastName'].value
        let dn = form['displayName'].value
        let pr = {...window.app.state.profile}
        pr.firstName = fn
        pr.lastName = ln
        pr.displayName = dn
        pr.bio = bio
        window.app.updateProfile({profile: pr}).then(result => {
            if (result) {
                form.querySelector('#closeBasics').click()
            } else {
                warning('NO UPDATE, TRY AGAIN')
            }
        }).catch(e => warning(e))
    }
    openCreatePost = (e) => {
        e.target.removeEventListener('click', this.openCreatePost)
        e.target.innerHTML = 'Close Post Form'
        e.target.parentElement.parentElement.querySelector('#CP').classList.remove('hidden')
        e.target.addEventListener('click', this.closeCreatePost)
        console.log('open')
    }
    closeCreatePost = (e) => {
        e.target.removeEventListener('click', this.closeCreatePost)
        e.target.parentElement.parentElement.querySelector('#CP').classList.add('hidden')
        e.target.innerHTML = 'Create Post'
        e.target.addEventListener('click', this.openCreatePost)
        console.log('close')
    }
    addFriend = (e) => {
        if (this.props.profile.user !== window.app.state.userID) window.app.sendData('/addfriend', {userID: window.app.state.userID, user: this.props.profile.user}, window.app.state.auth).then(result => window.app.validate(result)).then(result => {
            window.app.setState({sentRequests: result.sentRequests})
            sessionStorage.setItem('sentRequests', JSON.stringify(window.app.state.sentRequests))
        }).catch(e => console.log(e))
    }
    removeFriend = (e) => {
        if (this.props.profile.user !== window.app.state.userID) window.app.sendData('/removefriend', {userID: window.app.state.userID, user: this.props.profile.user}, window.app.state.auth).then(result => window.app.validate(result)).then(result => {
            window.app.setState({friendList: result.friendList})
            sessionStorage.setItem('friendList', window.app.state.friendList)
            this.render()
        }).catch(e => console.log(e))
    }
    cancelFriendRequest = (e) => {
        if (this.props.profile.user !== window.app.state.userID) window.app.sendData('/cancelfriend', {userID: window.app.state.userID, user: this.props.profile.user}, window.app.state.auth).then(result => window.app.validate(result)).then(result => {
            window.app.setState({sentRequests: result.sentRequests})
            sessionStorage.setItem('sentRequests', window.app.state.sentRequests)
            this.render()
        }).catch(e => console.log(e))
    }
    acceptFriendRequest = (e) => {
        if (this.props.profile.user !== window.app.state.userID) window.app.sendData('/acceptfriend', {userID: window.app.state.userID, user: this.props.profile.user}, window.app.state.auth).then(result => window.app.validate(result)).then(result => {
            window.app.setState({friendList: result.friendList, friendRequests: result.friendRequests})
            sessionStorage.setItem('friendList', window.app.state.friendList)
            sessionStorage.setItem('friendRequests', window.app.state.friendRequests)
        }).catch(e => console.log(e))
    }
    addLike = (e) => {
        let id = e.target.parentElement.parentElement.id.split('').map((l, i) => i !== 0 ? l : '').join('')
        let that = e.target
        let count
        if (that) {
            count = that.parentElement?.querySelector('span')?.innerHTML
        }
        let done = () => {
            if (that) {
                that.innerHTML = 'Liked'
                let a = that.parentElement?.querySelector('span')
                if (a) {
                    let b = a.innerHTML.split(' ')
                    if (b && b.length > 0) {
                        try {
                            let num = parseInt(b[b.length - 1]) + 1
                            let c = count ? count.split(' ') : null
                            let cnum = parseInt(c[c.length - 1]) + 1
                            a.innerHTML = `POST LIKES: ${cnum ? (num > cnum ? cnum : num) : num}`
                        } catch {
                            console.log('Couldn\'t update post count.')
                        }
                    }
                }
            }
        }
        if (id) window.app.sendData('/like', {userID: window.app.state.userID, post: id}, window.app.state.auth).then(result => window.app.validate(result)).then(result => {
            let posts = [...window.app.state.posts].map(post => post._id === result?.post?._id ? result?.post : post)
            window.app.setState({posts: window.clean(posts)})
            done()
            if (this.props.profile.user !== window.app.state.userID) this.render()
        }).catch(e => {console.log(e)})
    }
    removeLike = (e) => {
        let id = e.target.parentElement.parentElement.id.split('').map((l, i) => i !== 0 ? l : '').join('')
        let that = e.target
        let done = () => {
            if (that) {
                that.innerHTML = 'Like'
                let a = that.parentElement?.querySelector('span')
                if (a) {
                    let b = a.innerHTML.split(' ')
                    if (b && b.length > 0) {
                        try {
                            a.innerHTML = `POST LIKES: ${parseInt(b[b.length - 1]) - 1}`
                        } catch {
                            console.log('Couldn\'t update post count.')
                        }
                    }
                }
            }
        }
        if (id) window.app.sendData('/unlike', {userID: window.app.state.userID, post: id}, window.app.state.auth).then(result => window.app.validate(result)).then(result => {
            let posts = [...window.app.state.posts].map(post => post._id === result?.post?._id ? result?.post : post)
            window.app.setState({posts: window.clean(posts)})
            if (result.post) done()
            if (this.props.profile.user !== window.app.state.userID) this.render()
        }).catch(e => {console.log(e)})
    }
    handleLike = (e) => {
        if (/liked/i.test(e.target.innerHTML)) {
            this.removeLike(e)
        } else {
            this.addLike(e)
        }
    }
    toggleClasses2 = (e) => {
        let im = e.target?.parentElement?.parentElement?.parentElement?.parentElement?.querySelector('image-selector')
        if (im) {
            if (im.classList.contains('hidden')) {
                im.classList.remove('hidden')
            } else {
                im.classList.add('hidden')
                if (im.selectedID) {
                    e.target.innerHTML = '+1 Image'
                } else {
                    e.target.innerHTML = 'Add Image'
                }
            }
        }
    }
    toggleClasses3 = (e) => {
        let im = e.target?.parentElement?.parentElement?.parentElement?.querySelector('image-selector')
        if (im.classList.contains('hidden')) {im.classList.remove('hidden')} else {
            im.classList.add('hidden')
            if (im.selectedID) {
                e.target.innerHTML = '+1 Image'
            } else {
                e.target.innerHTML = 'Add Image'
            }
        }
    }
    render(){
        let cy
        if ((!window.app.state.userID || window.app.state.userID === 'undefined') && (window.app.state.auth && window.app.state.auth !== 'undefined')) {
            cy = {
                zIndex: '0'
            } 
        }
        let d = this.props.showProfile === true ? true : false
        let h = window.app.state.userID === this.props.profile?.user ? '' : 'hidden'
        let c = (d === true || this.props.close) ? 'profileCont' : 'profileCont hidden'
        const profile = this.props.profile
        if (!profile || !profile.firstName) {return (<div></div>)}
        let fStatText
        let fStat
        if (h !== 'hidden') {
            fStat = () => console.log('friends of course')
        } else if (window.app.state.friendList && window.app.state.friendList.length > 0 && window.app.state.friendList.includes(profile.user)) {
            fStatText = 'Remove Friend'
            fStat = this.removeFriend
        } else if (window.app.state.sentRequests && window.app.state.sentRequests.length > 0 && window.app.state.sentRequests.includes(profile.user)) {
            fStatText = 'Cancel Request'
            fStat = this.cancelFriendRequest
        } else if (window.app.state.friendRequests && window.app.state.friendRequests.length > 0 && window.app.state.friendRequests.includes(profile.user)) {
            fStatText = 'Accept Request'
            fStat = this.acceptFriendRequest            
        } else {
            fStatText = 'Add Friend'
            fStat = this.addFriend
        }
        let pic = profile.profilePic ? window.app.state.images.find(img => img._id === profile.profilePic) : {}
        let displayName = profile.displayName ? profile.displayName : 'No Display Name Chosen'
        let firstName = profile.firstName ?  profile.firstName : 'Jo'
        let lastName = profile.lastName ? profile.lastName : 'Doe'
        let bio = profile.bio ? profile.bio : h === 'hidden' ? '' : 'Introduce yourself here. Tell us about you, your experience working out and what your fitness goals.'
        //let colors = profile.colors ? profile.colors : {prim: 'var(--prim)', sec: 'var(--sec)', dark: 'var(--dark)', light: 'var(--light)'}
        //let darkmode = profile.darkmode ? profile.darkmode : false
        let posts
        if (profile.posts && profile.posts.length > 0) {
            posts = window.clean(window.app.state.posts.filter(p => profile.posts.includes(p._id)))
        } else {
            posts = []
        }
        let cl
        if (h === 'hidden') {
            cl = 'newtodo'
            cy = {
                zIndex: '10',
                animation: 'slideup 300ms ease-out 0 1 normal running'
            }
        } else {
            cl = 'hidden'
        }
        return(<div className={c} style={cy ? cy : {}}>
            <div className={cl} onClick={this.props.close}>CLOSE PROFILE</div>
            <span style={{justifySelf: 'center', width: '100%', maxWidth: '100%', margin: '0', padding: '2px 0px'}}>{h === 'hidden' ? `${displayName}'s Profile` : 'YOUR PROFILE'}</span>
            <div className="b1" id="profHead">
                <div className="b3">{pic ? <ProfilePic img={pic}></ProfilePic> : this.props.close ? <image-find img={profile.profilePic}></image-find> : <ProfilePic></ProfilePic>}<button onClick={this.changePic} className={h}>CHANGE</button></div>
                <div className="b1" id="profInfo"><h1>{displayName}, {firstName} {lastName}</h1>
                <p>{bio}</p><span className={h} onClick={this.editAbout}>EDIT</span></div>
                <div className="b1 hidden" id="uProfInfo">
                    <form id="form1" onSubmit={this.profileUpdate1}>
                        <h4>The Basics</h4>
                        <div className="warn"></div>
                        <div className="b2"><label htmlFor="firstName">First Name</label><input name="firstName" id="firstName" type="text" value={this.state.firstName} onChange={this.changeHandle1} minLength={2}></input></div>
                        <div className="b2"><label htmlFor="lastName">Last Name</label><input name="lastName" id="lastName" type="text" value={this.state.lastName} onChange={this.changeHandle1} minLength={2}></input></div>
                        <div className="b2"><label htmlFor="displayName">Display Name</label><input name="displayName" id="displayName" type="text" value={this.state.displayName} onChange={this.changeHandle1} minLength={1}></input></div>
                        <div className="b1"><label htmlFor="bio">Bio</label><textarea value={this.state.bio} onChange={this.changeHandle1} name="bio" style={{textAlign: 'center', padding: '10px'}}></textarea></div>
                        <div className="b2"><button type="submit">CONFIRM</button> <div onClick={this.closeEAbout} id="closeBasics">CLOSE</div></div>
                    </form>
                </div>
            </div>
            <div className={'b1 hidden'} id="CP">
                <image-selector id="profileimgselect" class="hidden"></image-selector>
                <div id="newpost">
                    <form id="post-form" onSubmit={this.createPost}>
                        <h1>MAKE A POST</h1>
                        <div className="warn"></div>
                        <div className="b1"><label htmlFor="header">Header</label><input type="text" placeholder="header" name="header"></input></div>
                        <div className="b1"><label htmlFor="body">Your Content</label><textarea minLength={1} placeholder="Write everything here." name="body"></textarea></div>
                        <div className="b2"><button id="FBU" onClick={(e) => {e.target.innerHTML = '<load-circle></load-circle>'}} type="submit">SUBMIT</button><div className="button" id="AAButton" onClick={this.toggleClasses2}>ADD IMAGE</div></div>
                    </form>
                </div>
            </div>
            <div className={'b2 ' + h} id="editProfButs"><button id="cp2b">Create Post</button><button onClick={window.app.uploadImage}>Upload Image</button></div>
        <div className={'b2 ' + (h === 'hidden' ? '' : 'hidden')} id="profButs"><button id="p1b" onClick={fStat}>{fStatText}</button><button onClick={window.app.startMessage}>Send Message</button></div>
        <div id="postwall" className="b1" style={{justifyContent: 'flex-start'}}>{posts.map((post, key) => {
                let like = post.likes.find(u => u.user === window.app.state.userID)
                let likeAction
                if (like){
                    likeAction = this.handleLike //this.removeLike
                } else {
                    likeAction = this.handleLike //this.addLike
                }
                return(<div className="post" id={'e' + post._id} key={key}>
                    <div id="postContents"><h2>{post.header}</h2>
                    {post.images ? post.images.map((im, key) => {
                        let image = window.app.state.images.find(img => img._id === im)
                        if (!image) return (<image-find key={key} img={im}></image-find>)
                        return (<img-f key={key} img={image.src} hue={image.hue} 
                        saturate={image.saturate} grayscale={image.grayscale} border={image.border} 
                        brightness={image.brightness} contrast={image.contrast} blur={image.blur} 
                    invert={image.invert} opacity={image.opacity} sepia={image.sepia} shadow={'1px 2px 3px #00000050'}></img-f>)
                    }) : ''}{post.video ? <div></div> : ''}
                    <p>{post.body}</p></div>
                    <div className="postInteractions"><span>POST LIKES: {post.likes ? post.likes.length : 0}</span><button className={h === 'hidden' ? '' : 'hidden'} onClick={likeAction}>{like ? 'Liked' : 'Like'}</button></div>
                    <div id="editPost" className={h}><image-selector select={post.images ? post.images[0] : ''} id="editPostImg" class="hidden"></image-selector>
                    <form id="editPostForm" onSubmit={this.editPost} className="hidden">
                        <h1>EDIT POST</h1>
                        <div className="warn"></div>
                        <div className="b1"><label htmlFor="header">Header</label><input value={this.state.pHead} type="text" onChange={this.changeHandle2} name="header"></input></div>
                        <div className="b1"><label htmlFor="body">Your Content</label><textarea minLength={1} value={this.state.pBody} onChange={this.changeHandle2} name="body"></textarea></div>
                        <div className="b2"><button id="EP" onClick={(e) => {e.target.innerHTML = '<load-circle></load-circle>'}} type="submit">SUBMIT</button><div className="button" id="AAButton" onClick={this.toggleClasses3}>{post.images ? '+1 Image' : 'ADD IMAGE'}</div></div>
                    </form>
                    <div id="delSure" className="hidden"><button onClick={this.deletePost}>DELETE POST</button><button onClick={(e) => e.target.parentElement.classList.add('hidden')}>CANCEL</button></div>
                    <div id="userOpt" className={'b2'}><span onClick={this.startEditPost}>EDIT</span><span onClick={this.delSure}>DELETE</span></div></div>
                    <div id="postComments"></div>
                </div>)
            })}</div>
            <div className="breaker"></div>
        <div className="profSidebar"></div>
        </div>)
    }
}
export default Profile