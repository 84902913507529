import React, { Component }  from 'react'
import './filteredimage'
class CropImage extends HTMLElement {
    constructor(){
        super()
        this.shadow = this.attachShadow({mode:'open'})
        this.s = 1
        this.x = 0
        this.y = 0
        this.ox = 0
        this.oy = 0
        this.w = null
        this.h = null
        this.mouse = false
        this.factor = 1
        this.controller = (e) => {
            e.preventDefault()
            const elem1 = e.target
            console.log(e.type, e.target?.id)
            switch(e.type) {
                case 'mousedown': {
                    const x = Math.floor(e.clientX - this.rect.left)
                    const y = Math.floor(e.clientY - this.rect.top)
                    this.previousX = x
                    this.previousY = y
                    this.mouse = true
                    window.addEventListener('mouseup', this.controller)
                    switch(e.target.id) {
                        case 'ne': {
                            return elem1.style.cursor = 'grabbing'
                        }
                        case 'nw':{
                            return elem1.style.cursor = 'grabbing'
                        }
                        case 'canvas': {
                            return elem1.style.cursor = 'grabbing'
                        }
                        default:{                            
                            console.log('DEFAULT DRAGSTART')
                        }
                    }
                    break
                }
                case 'touchstart': {
                    const y = Math.floor(e.changedTouches[0].clientY - this.rect.top)
                    const x = Math.floor(e.changedTouches[0].clientX - this.rect.left)
                    this.previousX = x
                    this.previousY = y
                    switch(e.target.id){
                        case 'canvas': {
                            elem1.style.cursor = 'grabbing'
                            break
                        }
                        default:{
                            console.log('DEFAULT touchstart')
                        }
                    }
                    break
                }
                case 'mousemove': {
                    const x = Math.floor(e.clientX - this.rect.left)
                    const y = Math.floor(e.clientY - this.rect.top)
                    if (this.mouse === true) {
                        switch(e.target.id){
                            case 'ne': {
                                this.ow = Math.max(1, Math.min(this.maxW, Math.floor(this.ow - ((x - this.previousX)*-1)*(this.factor))))
                                this.oy = Math.max(1, Math.floor(this.oy - ((y - this.previousY)*-1)*(this.factor)))
                                this.w = Math.max(1, Math.min(Math.floor(this.w - ((x - this.previousX)*-1)*(this.factor)), this.maxW))
                                this.y = Math.max(1, Math.floor(this.y - (((y - this.previousY)*-1)*(this.factor))*(this.factor)))
                                this.draw()
                                this.previousX = x
                                this.previousY = y
                                return
                            }
                            case 'nw': {
                                this.ox = Math.max(1, Math.min(Math.floor(this.ox - (((x - this.previousX)*-1)*(this.factor))*(this.factor)), this.maxW))
                                this.oy = Math.max(1, Math.min(Math.floor(this.oy - (((y - this.previousY)*-1)*(this.factor))*(this.factor)), this.maxH))
                                this.x = Math.max(1, Math.min(Math.floor(this.x - (((x - this.previousX)*-1)*(this.factor))*(this.factor)), this.maxW))
                                this.y = Math.max(1, Math.min(Math.floor(this.y - (((y - this.previousY)*-1)*(this.factor))*(this.factor)), this.maxH))
                                this.draw()
                                this.previousX = x
                                this.previousY = y
                                return
                            }
                            case 'se': {
                                let ow = Math.floor(this.ow - (((x - this.previousX)*-1)*(this.factor))*(this.factor))
                                let oy = Math.floor(this.oh - (((y - this.previousY)*-1)*(this.factor))*(this.factor))
                                let aw = Math.floor(this.w - (((x - this.previousX)*-1)*(this.factor))*(this.factor))
                                let ay = Math.floor(this.h - (((y - this.previousY)*-1)*(this.factor))*(this.factor))
                                this.ow = ow >= 0 ? ow : 0
                                this.oh = oy <= this.maxH ? oy : this.maxH
                                this.w = aw >= 0 ? aw : 0
                                this.h = ay <= this.maxH ? ay : this.maxH
                                this.draw()
                                this.previousX = x
                                this.previousY = y
                                return
                            }
                            case 'sw': {
                                let ow = Math.floor(this.ox - ((x - this.previousX)*-1)*(this.factor))
                                let oy = Math.floor(this.oh - ((y - this.previousY)*-1)*(this.factor))
                                let aw = Math.floor(this.x - ((x - this.previousX)*-1)*(this.factor))
                                let ay = Math.floor(this.h - ((y - this.previousY)*-1)*(this.factor))
                                this.ox = ow >= 0 ? ow : 0
                                this.oh = oy <= this.maxH ? oy : this.maxH
                                this.x = aw >= 0 ? aw : 0
                                this.h = ay <= this.maxH ? ay : this.maxH
                                this.draw()
                                this.previousX = x
                                this.previousY = y
                                return
                            }
                            default:{                           
                                elem1.style.cursor = 'grabbing'
                                let nx = (this.previousX ? this.previousX : 0) - x
                                let ny = (this.previousY ? this.previousY : 0) - y
                                this.ox = Math.max(0, Math.floor(this.ox ? this.ox : 0 - nx)*-1)
                                this.oy = Math.max(0, Math.floor(this.oy ? this.ox : 0 - ny)*-1)
                                this.previousX = e.changedTouches[0].clientX
                                this.previousY = e.changedTouches[0].clientY
                                this.draw()
                                console.log('DEFAULT touchend')
                            }
                        }
                    }
                    break
                }
                case 'touchend': {
                    const y = Math.floor(e.changedTouches[0].clientY - this.rect.top)
                    const x = Math.floor(e.changedTouches[0].clientX - this.rect.left)
                    switch(e.target.id){
                        case 'ne': {
                            this.ow = Math.max(1, Math.min(this.maxW, Math.floor(this.ow - ((x - this.previousX)*-1)*(this.factor))))
                            this.oy = Math.max(1, Math.floor(this.oy - ((y - this.previousY)*-1)*(this.factor)))
                            this.w = Math.max(1, Math.min(Math.floor(this.w - ((x - this.previousX)*-1)*(this.factor)), this.maxW))
                            this.y = Math.max(1, Math.floor(this.y - ((y - this.previousY)*-1)*(this.factor)))
                            this.draw()
                            this.previousX = x
                            this.previousY = y
                            return
                        }
                        case 'nw': {
                            this.ox = Math.max(1, Math.min(Math.floor(this.ox - ((x - this.previousX)*-1)*(this.factor)), this.maxW))
                            this.oy = Math.max(1, Math.min(Math.floor(this.oy - ((y - this.previousY)*-1)*(this.factor)), this.maxH))
                            this.x = Math.max(1, Math.min(Math.floor(this.x - ((x - this.previousX)*-1)*(this.factor)), this.maxW))
                            this.y = Math.max(1, Math.min(Math.floor(this.y - ((y - this.previousY)*-1)*(this.factor)), this.maxH))
                            this.draw()
                            this.previousX = x
                            this.previousY = y
                            return
                        }
                        case 'se': {
                            let ow = Math.floor(this.ow - ((x - this.previousX)*-1)*(this.factor))
                            let oy = Math.floor(this.oh - ((y - this.previousY)*-1)*(this.factor))
                            let aw = Math.floor(this.w - ((x - this.previousX)*-1)*(this.factor))
                            let ay = Math.floor(this.h - ((y - this.previousY)*-1)*(this.factor))
                            this.ow = ow >= 0 ? ow : 0
                            this.oh = oy <= this.maxH ? oy : this.maxH
                            this.w = aw >= 0 ? aw : 0
                            this.h = ay <= this.maxH ? ay : this.maxH
                            this.draw()
                            this.previousX = x
                            this.previousY = y
                            return
                        }
                        case 'sw': {
                            let ow = Math.floor(this.ox - ((x - this.previousX)*-1)*(this.factor))
                            let oy = Math.floor(this.oh - ((y - this.previousY)*-1)*(this.factor))
                            let aw = Math.floor(this.x - ((x - this.previousX)*-1)*(this.factor))
                            let ay = Math.floor(this.h - ((y - this.previousY)*-1)*(this.factor))
                            this.ox = ow >= 0 ? ow : 0
                            this.oh = oy <= this.maxH ? oy : this.maxH
                            this.x = aw >= 0 ? aw : 0
                            this.h = ay <= this.maxH ? ay : this.maxH
                            this.draw()
                            this.previousX = x
                            this.previousY = y
                            return
                        }
                        default:{                           
                            elem1.style.cursor = 'grabbing'
                            let nx = (this.previousX ? this.previousX : 0) - x
                            let ny = (this.previousY ? this.previousY : 0) - y
                            this.ox = Math.max(0, Math.floor(this.ox ? this.ox : 0 - nx)*-1)
                            this.oy = Math.max(0, Math.floor(this.oy ? this.ox : 0 - ny)*-1)
                            this.previousX = e.changedTouches[0].clientX
                            this.previousY = e.changedTouches[0].clientY
                            this.draw()
                            console.log('DEFAULT touchend')
                        }
                    }
                    break
                }                
                case 'touchmove': {
                    const y = Math.floor(e.changedTouches[0].clientY - this.rect.top)
                    const x = Math.floor(e.changedTouches[0].clientX - this.rect.left)
                    switch(e.target.id){
                        case 'ne': {
                            this.ow = Math.max(1, Math.min(this.maxW, Math.floor(this.ow - ((x - this.previousX)*-1)*(this.factor))))
                            this.oy = Math.max(1, Math.floor(this.oy - ((y - this.previousY)*-1)*(this.factor)))
                            this.w = Math.max(1, Math.min(Math.floor(this.w - ((x - this.previousX)*-1)*(this.factor)), this.maxW))
                            this.y = Math.max(1, Math.floor(this.y - ((y - this.previousY)*-1)*(this.factor)))
                            this.draw()
                            this.previousX = x
                            this.previousY = y
                            return
                        }
                        case 'nw': {
                            this.ox = Math.max(1, Math.min(Math.floor(this.ox - ((x - this.previousX)*-1)*(this.factor)), this.maxW))
                            this.oy = Math.max(1, Math.min(Math.floor(this.oy - ((y - this.previousY)*-1)*(this.factor)), this.maxH))
                            this.x = Math.max(1, Math.min(Math.floor(this.x - ((x - this.previousX)*-1)*(this.factor)), this.maxW))
                            this.y = Math.max(1, Math.min(Math.floor(this.y - ((y - this.previousY)*-1)*(this.factor)), this.maxH))
                            this.draw()
                            this.previousX = x
                            this.previousY = y
                            return
                        }
                        case 'se': {
                            let ow = Math.floor(this.ow - ((x - this.previousX)*-1)*(this.factor))
                            let oy = Math.floor(this.oh - ((y - this.previousY)*-1)*(this.factor))
                            let aw = Math.floor(this.w - ((x - this.previousX)*-1)*(this.factor))
                            let ay = Math.floor(this.h - ((y - this.previousY)*-1)*(this.factor))
                            this.ow = ow >= 0 ? ow : 0
                            this.oh = oy <= this.maxH ? oy : this.maxH
                            this.w = aw >= 0 ? aw : 0
                            this.h = ay <= this.maxH ? ay : this.maxH
                            this.draw()
                            this.previousX = x
                            this.previousY = y
                            return
                        }
                        case 'sw': {
                            let ow = Math.floor(this.ox - ((x - this.previousX)*-1)*(this.factor))
                            let oy = Math.floor(this.oh - ((y - this.previousY)*-1)*(this.factor))
                            let aw = Math.floor(this.x - ((x - this.previousX)*-1)*(this.factor))
                            let ay = Math.floor(this.h - ((y - this.previousY)*-1)*(this.factor))
                            this.ox = ow >= 0 ? ow : 0
                            this.oh = oy <= this.maxH ? oy : this.maxH
                            this.x = aw >= 0 ? aw : 0
                            this.h = ay <= this.maxH ? ay : this.maxH
                            this.draw()
                            this.previousX = x
                            this.previousY = y
                            return
                        }
                        default:{                            
                            elem1.style.cursor = 'grabbing'
                            let nx = (this.previousX ? this.previousX : 0) - x
                            let ny = (this.previousY ? this.previousY : 0) - y
                            this.ox = Math.max(0, Math.floor(this.ox ? this.ox : 0 - nx)*-1)
                            this.oy = Math.max(0, Math.floor(this.oy ? this.ox : 0 - ny)*-1)
                            this.previousX = e.changedTouches[0].clientX
                            this.previousY = e.changedTouches[0].clientY
                            this.draw()
                            console.log('DEFAULT touchend')
                        }
                    }
                    break
                }
                case 'mouseup': {
                    const x = Math.floor(e.clientX - this.rect.left)
                    const y = Math.floor(e.clientY - this.rect.top)
                    this.mouse = false
                    window.removeEventListener('mouseup', this.controller)
                    switch(e.target.id){
                        case 'ne': {
                            this.ow = Math.max(1, Math.min(this.maxW, Math.floor(this.ow - ((x - this.previousX)*-1)*(this.factor))))
                            this.oy = Math.max(1, Math.floor(this.oy - ((y - this.previousY)*-1)*(this.factor)))
                            this.w = Math.max(1, Math.min(Math.floor(this.w - ((x - this.previousX)*-1)*(this.factor)), this.maxW))
                            this.y = Math.max(1, Math.floor(this.y - ((y - this.previousY)*-1)*(this.factor)))
                            this.draw()
                            this.previousX = x
                            this.previousY = y
                            this.ne.style.cursor = 'grab'
                            return
                        }
                        case 'nw': {
                            this.ox = Math.max(1, Math.min(Math.floor(this.ox - ((x - this.previousX)*-1)*(this.factor)), this.maxW))
                            this.oy = Math.max(1, Math.min(Math.floor(this.oy - ((y - this.previousY)*-1)*(this.factor)), this.maxH))
                            this.x = Math.max(1, Math.min(Math.floor(this.x - ((x - this.previousX)*-1)*(this.factor)), this.maxW))
                            this.y = Math.max(1, Math.min(Math.floor(this.y - ((y - this.previousY)*-1)*(this.factor)), this.maxH))
                            this.draw()
                            this.previousX = x
                            this.previousY = y
                            this.nw.style.cursor = 'grab'
                            return
                        }
                        case 'se': {
                            let ow = Math.floor(this.ow - ((x - this.previousX)*-1)*(this.factor))
                            let oy = Math.floor(this.oh - ((y - this.previousY)*-1)*(this.factor))
                            let aw = Math.floor(this.w - ((x - this.previousX)*-1)*(this.factor))
                            let ay = Math.floor(this.h - ((y - this.previousY)*-1)*(this.factor))
                            this.ow = ow >= 0 ? ow : 0
                            this.oh = oy <= this.maxH ? oy : this.maxH
                            this.w = aw >= 0 ? aw : 0
                            this.h = ay <= this.maxH ? ay : this.maxH
                            this.draw()
                            this.previousX = x
                            this.previousY = y
                            return
                        }
                        case 'sw': {
                            let ow = Math.floor(this.ox - ((x - this.previousX)*-1)*(this.factor))
                            let oy = Math.floor(this.oh - ((y - this.previousY)*-1)*(this.factor))
                            let aw = Math.floor(this.x - ((x - this.previousX)*-1)*(this.factor))
                            let ay = Math.floor(this.h - ((y - this.previousY)*-1)*(this.factor))
                            this.ox = ow >= 0 ? ow : 0
                            this.oh = oy <= this.maxH ? oy : this.maxH
                            this.x = aw >= 0 ? aw : 0
                            this.h = ay <= this.maxH ? ay : this.maxH
                            this.draw()
                            this.previousX = x
                            this.previousY = y
                            return
                        }
                        case 'canvas':{
                            elem1.style.cursor = 'grabbing'
                            let nx = (this.previousX ? this.previousX : 0) - x
                            let ny = (this.previousY ? this.previousY : 0) - y
                            this.ox = Math.max(0, Math.floor(this.ox ? this.ox : 0 - nx)*-1)
                            this.oy = Math.max(0, Math.floor(this.oy ? this.ox : 0 - ny)*-1)
                            this.previousX = e.changedTouches[0].clientX
                            this.previousY = e.changedTouches[0].clientY
                            this.draw()
                            return
                        }
                        default:{                           
                            console.log('DEFAULT touchend')
                        }
                    }
                    break
                }
                default: {
                    console.log('DEFAULT')
                }
            }
            console.log(this.previousX, this.previousY, this.x, this.y, this.w, this.h)
        }
    }
    render(){
        this.shadow.innerHTML = `<style>   
            .hidden {
                display: none!important;
            }      
            #cont {
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                max-width: 100%;
                overflow: hidden;
                margin: 0;
                padding: 0;
                width: 100%;
            }
            #canvasCont {
                position: relative;
                max-width: 100%;
                margin: 40px 0px;
                padding: 0px;
                border: 2px dotted var(--prim);
            }
            canvas {
                max-width: 50%;
                width: auto;
                height: auto;
                border: 1px var(--prim) dotted;
                cursor: default;
            }
            span {
                width: 30px;
                height: 30px;
                border-radius: 100px;
                background-color: var(--prim);
                cursor: pointer;
                position: absolute;
                display: block;
                z-index: 100;
            }
            #ne {
                cursor: grab;
            }
            #nw {
                cursor: grab;
            }
            #se {
                cursor: se-resize;
            }
            #sw {
                cursor: sw-resize;
            }
            #scaleHold {
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                max-width: 100%;
                overflow: hidden;
                margin: 0;
                padding: 0;
                width: 100%;
            }
            p {
                font-size: .8rem;
                color: var(--light);
                font-weight: 800;
            }
            #reset {
                color: var(--light);
                font-weight: 800;
                background-color: var(--dark);
                border: 1px solid var(--prim);
                box-shadow: 1px 2px 3px var(--prim);
                font-size: 1.2rem;
                cursor: pointer;
                margin: 10px;
                padding: 8px 12px;
                border-radius: 50px;
            }
            #scale {
                max-width: 80%;
                transform: scale(1.5,1.5);
            }
            #warn {
                word-break: break-word;
                color: var(--warn);
                font-weight: 800;
                font-size: .8rem;
                text-align: center;
                max-width: 100%;
                margin: 2px 0px;
                padding: 5px 15px;
            }
            .warnCrop {
                background-color: var(--warn)!important;
                opacity: .9;
            }
            @media screen and (max-width: 1080px) {
                canvas {
                    max-width: 100%;
                }
            }
        </style>
        <div id="cont">
            <div id="warn"></div>
            <div id="canvasCont">
                <span draggable="true" style="top: 0px; left: 0px;" id="nw"></span>
                <span draggable="true" style="top: 0px; left: 0px;" id="ne"></span>
                <canvas draggable="true" id="canvas"></canvas>
                <span draggable="true" style="top: 0px; left: 0px;" id="sw"></span>
                <span draggable="true" style="top: 0px; left: 0px;" id="se"></span>
            </div>
            <div id="scaleHold">
                <p id="scaleNote">Scale (1x)</p>
                <input id="scale" class="hidden" style="cursor: pointer;" type="range" value="1" min=".5" max="2" step=".5">
                <div id="reset">RESET IMAGE</div>
            </div>
        </div>`
        this.ne = this.shadow.querySelector('#ne')
        this.nw = this.shadow.querySelector('#nw')
        this.se = this.shadow.querySelector('#se')
        this.sw = this.shadow.querySelector('#sw')
        this.addCanvas()
        this.shadow.querySelector('#reset').addEventListener('click', (e) => {
            this.resetImage()
        })
        this.shadow.querySelector('#scale').addEventListener('change', (e) => {
            console.log(e.target.value)
            this.changeScale(e.target.value)
            this.draw()
        })
        Array.from(this.shadow.querySelectorAll('span')).forEach(s => {
            s.addEventListener('mousedown', this.controller)
            s.addEventListener('touchstart', this.controller)
            s.addEventListener('touchend', this.controller)
            s.addEventListener('mouseup', this.controller)
            s.addEventListener('mousemove', this.controller)
            s.addEventListener('touchmove', this.controller)
        })
    }
    warn(e, time){
        console.log(e)
        clearTimeout(this.warning)
        this.shadow.querySelector('#warn').innerHTML = e
        this.warning = setTimeout(() => {
            this.shadow.querySelector('#warn').innerHTML = ''
        }, time ? time : 500)
    }
    draw() {
        console.log('RENDERING:','ox',this.ox, 'oy',this.oy,'ow', this.ow,'oh', this.oh,'x',this.x, 'y',this.y,'w', this.w,'h', this.h, 'scale', this.s)
        this.context.clearRect(0,0,6000,6000)
        this.context.drawImage(this.image, this.ox, this.oy, this.ow, this.oh, this.x, this.y, this.w*this.s, this.h*this.s)
        if ((this.x > 0 || this.y > 0) && (this.h >= this.maxH || this.w >= this.maxW)) {
            this.warn('Outside cropzone!')
            this.shadow.querySelector('#canvasCont').classList.add('warnCrop')
        } else {
            this.shadow.querySelector('#canvasCont').className = ''
        }
        this.anchor()
    }
    resetImage(){
        this.image = null
        this.canvas = null
        this.context = null
        this.s = 1
        this.x = 0
        this.y = 0
        this.ox = 0
        this.oy = 0
        this.w = null
        this.h = null
        this.addCanvas()
        this.changeScale(1)
    }
    addCanvas = async () => {
        this.image = document.createElement('img')
        this.image.src = this.getAttribute('image')
        await new Promise(res => this.image.onload = res)
        this.canvas = this.shadow.querySelector('canvas')
        this.aspect = this.image.width/this.image.height
        if (this.image.width <= 1080 && this.aspect > .8) {
            this.factor = 1.5
        } else if (this.image.width <= 1080 && this.aspect < .8) {
            this.factor = 2
        } else if (this.image.width > 3500) {
            this.factor = 3
        } else if (this.image.width > 1080) {
            this.factor = 2.5
        }
        if (this.aspect < .6) {
            this.shadow.querySelector('#canvasCont').style.maxWidth = '33%'
        } else if (this.aspect < 1) {
            this.shadow.querySelector('#canvasCont').style.maxWidth = '50%'
        } else {
            this.shadow.querySelector('#canvasCont').style.maxWidth = '80%'
        }
        this.canvas.style = this.getAttribute('filter')
        this.canvas.width = this.image.width
        this.canvas.height = this.image.height
        this.oh = this.canvas.height
        this.ow = this.canvas.width
        this.maxW = this.canvas.width
        this.maxH = this.canvas.height
        this.h = this.oh
        this.w = this.ow
        this.context = this.canvas.getContext('2d')
        this.rect = this.canvas.getBoundingClientRect()
        this.maxT = this.rect.top
        this.maxL = this.rect.left
        this.maxR = this.rect.right
        this.maxB = this.rect.bottom
        this.ratioX = this.rect.width/this.ow
        this.ratioY = this.rect.height/this.oh
        this.draw()
    }
    anchor(){
        this.anchorBottom = Math.floor((this.h*this.ratioY*this.s)+this.y)
        this.anchorRight = Math.floor((this.w*this.ratioX*this.s)+this.x)
        this.anchorLeft = Math.floor(this.x)
        this.anchorTop = Math.floor(this.y)
        let bottom = this.anchorBottom <= 0 ? 0 : this.anchorBottom >= this.maxB ? this.maxB: this.anchorBottom
        let right = this.anchorRight <= 0 ? 0 : this.anchorRight >= this.maxR ? this.maxR : this.anchorRight
        let left = this.anchorLeft <= 0 ? 0 : this.anchorLeft > this.maxL ? this.maxL : this.anchorLeft
        let top = this.anchorTop <= 0 ? 0 : this.anchorTop > this.maxT ? this.maxT : this.anchorTop
        this.sw.style.top = bottom + 'px'
        this.sw.style.left = left + 'px'
        this.se.style.top = bottom + 'px'
        this.se.style.left = right + 'px'
        this.ne.style.left = right + 'px'
        this.ne.style.top = top + 'px'
        this.nw.style.top = top + 'px'
        this.nw.style.left = left + 'px'
    }
    changeScale(val){
        console.log(val)
        this.shadow.querySelector('#scaleNote').innerHTML = `Scale (${val}x)`
        this.s = val
        if (this.shadow.querySelector('#scaleNote').value !== val) {
            console.log('reset scale'); this.shadow.querySelector('#scaleNote').value = val
        }
    }
    connectedCallback(){
        if (this.hasAttribute(['image'])) {
            this.render()
        }        
    }
    static get observedAttributes(){
        return ['image', 'filter']
    }
    attributeChangedCallback(name, o, n){
        if (['image', 'filter'].includes(name)) this.render()
    }
}
customElements.define('crop-img', CropImage)
class EditImage extends HTMLElement {
    constructor(){
        super()
        this.shadow = this.attachShadow({mode:'open'})
        this.properties = {
            brightness: 100,
            contrast: 100,
            hue: 0,
            grayscale: 0,
            saturate: 100,
            invert: 0,
            blur: 0,
            sepia: 0,
            opacity: 100,
            border: 0
        }
        this.selected = 'brightness'
        this.select = (e) => {
            this.selected = e.target.innerHTML
            this.shadow.querySelector('#selected').innerHTML = this.selected
            let tog = this.shadow.querySelector('#tog')
            if (['brightness', 'contrast', 'saturate'].includes(this.selected)) {
                tog.max = 200
                tog.min = 0
                tog.value = this.properties[this.selected]
            } else if(this.selected === 'hue'){
                tog.min = -180
                tog.max = 180
                tog.value = this.properties[this.selected]
            } else if(['opacity', 'grayscale', 'border'].includes(this.selected)){
                tog.min = 0
                tog.max = 100
                tog.value = this.properties[this.selected]
            } else if(this.selected === 'blur'){
                tog.min = 0
                tog.max = 12
                tog.value = this.properties[this.selected]
            } else {
                tog.min = 0
                tog.max = 100
                tog.value = this.properties[this.selected]
            }
            this.selectBut(e.target)
        }
        this.filter = (e) => {
            let value
            if (this.selected === 'opacity') {
                value = e.target.value/100
            } else {
                value = e.target.value
            }
            this.properties[this.selected] = value
            let a = this.i
            if (a) {
                a.setAttribute(this.selected, value)
            }
            console.log(`OPACITY TEST: ${this.properties['opacity']}`)
        }
    }
    selectBut(e){
        Array.from(this.shadow.querySelectorAll('button')).forEach(but => {
            if (but === e) {
                but.classList.add('a')
            } else {
                but.classList.remove('a')
            }
        })
    }
    set(){
        if (this.i) {
            for (const [key, value] of Object.entries(this.properties)) {
                this.i.setAttribute(key, value)
            }
        }
    }
    render(){
        this.shadow.innerHTML = `<style>
            * {
                margin: 0;
                padding: 0;
            }
            .hidden {
                display: none!important;
            }
            #cont {
                display: grid;
                grid-template-columns: 2fr 1fr;
                grid-template-rows: 4fr 1fr;
                align-items: center;
                justify-content: center;
                max-width: 100%;
                overflow-x: hidden;
                margin: 0;
                padding: 0;
                width: 100%;
                max-height: 90vh;
            }
            #sidebar {
                grid-area: 1 / 2 / span 1 / span 1;
                background-color: var(--dark);
                display: inline-flex;
                align-items: center;
                justify-content: center;
                max-width: 100%;
                width: 100%;
                margin: 0;
                padding: 0;
                overflow: hidden;
            }
            #togbox {
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                max-width: 100%;
                overflow: hidden;
                margin: 0;
                padding: 0;
                width: 100%;
            }
            #buttons {
                background-color: var(--dark);
                display: grid;
                grid-template-columns: 1fr 1fr;
                max-width: 100%;
                width: 100%;
                margin: 0;
                padding: 0;
                grid-gap: 5px;
                overflow-x: hidden;
                background-color: var(--prim);
            }
            #buttons::-webkit-scrollbar {
                display: none!important;
            }
            #toggle {
                grid-area: 2 / 1 / span 1 / span 2;
                background-color: var(--prim);
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                max-width: 100%;
                overflow: hidden;
                margin: 0;
                padding: 0;
                width: 100%;
                height: 90%;
                max-height: 100%;
                border-radius: 10px;
                justify-content: space-between;
            }
            #img {
                grid-area: 1 / 1 / span 1 / span 1;
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                max-width: 100%;
                overflow: hidden;
                margin: 0;
                padding: 0;
                width: 100%;
            }

            #tog {
                width: 90%;
                max-width: 100%;
                margin: 10px 0px;
                padding: 0;
            }
            img-f {
                max-width: 80%;
                margin: 0px 10px;
                padding: 0;
            }
            crop-img {
                max-width: 100%;
                width: 100%;
                overflow: hidden;
                margin: 0;
                padding: 0;
            }
            .a {
                background-color: var(--dark);
                color: var(--prim);
            }
            .a:hover {
                opacity: 1;
            }
            button {
                background-color: var(--light);
                color: var(--dark);
                transtion: 300ms ease-in;
                cursor: pointer;
                max-width: 100%;
                text-align: center;
                font-weight: 800;
                text-transform: uppercase;
                margin: 0px;
                padding: 5px 8px;
                border-radius: 50px;
            }
            button:hover {
                transition: 200ms ease-out;
                background-color: var(--prim);
                color: var(--light);
                opacity: .9;
                text-shadow: 1px 2px 3px #00000040;
            }
            #selected {
                font-weight: 800;
                font-size: .8rem;
                color: var(--dark);
                max-width: 100%;
                margin: 0px;
                padding: 5px 0px;
                text-transform: capitalize;
            }
            #submit {
                color: var(--light);
                margin: 10px 0px;
                padding: 5px 10px;
                border-radius: 50px;
                background-color: var(--dark);
                cursor: pointer;
                transition: 400ms ease;
                max-width: 100%;
                font-weight: 800;
                box-shadow: 1px 2px 3px #00000040;
            }
            #submit:hover {
                background-color: var(--prim);
                color: var(--dark);
                transition: 250ms ease;
            }
            #close {
                position: fixed;
                top: 3%;
                left: 3%;
                font-size: 2rem;
                font-weight: 800;
                padding: 5px 10px;
                border-radius: 50px;
                z-index: 101;
                background-color: var(--prim);
                color: var(--dark);
                transition: 400ms ease;
                cursor: pointer;
            }
            #close:hover {
                background-color: var(--light);
                color: var(--prim);
                transition: 300ms ease;
            }
            #cropBox {
                grid-area: 1 / 1 / span 2 / span 2;
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                max-width: 100%;
                overflow: hidden;
                margin: 0;
                padding: 0;
                width: 100%;
            }
            #buttonBox {
                display: inline-flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
                max-width: 100%;
                overflow: hidden;
                margin: 0;
                padding: 0;
                width: 100%;
            }
            #crop {
                background-color: #fafafa;
                color: var(--dark);
                font-weight: 800;
                box-shadow: 1px 2px 3px #00000060;
                padding: 5px 10px;
                margin: 3px;
                border-radius: 50px;
                cursor: pointer;
                transition: 300ms ease;
            }
            #crop:hover {
                letter-spacing: 2px;
                background-color: var(--dark);
                color: var(--prim);
                transition: 300ms ease;
            }
            
            input[type='range']::-webkit-slider-runnable-track {
                -webkit-appearance: none;
                background-color: var(--dark);
                border-radius: 10px;
              }
              
              input[type='range']::-webkit-slider-thumb {
                -webkit-appearance: none;
                background-color: var(--prim);
                color: var(--prim);
                cursor: pointer;
              }
              input[type='range']::-mozkit-range-thumb {
                -webkit-appearance: none;
                background-color: var(--prim);
                color: var(--prim);
                cursor: pointer;
              }
              input[type="range"]::-moz-range-progress {
                background-color: var(--prim); 
              }
              input[type="range"]::-moz-range-track {  
                background-color: var(--dark);
              }
              /* IE*/
              input[type="range"]::-ms-fill-lower {
                background-color: var(--dark); 
              }
              input[type="range"]::-ms-fill-upper {  
                background-color: var(--prim);
              }
              @media screen and (max-width: 1080px) {
                #buttons {
                    grid-template-columns: 1fr;
                    grid-template-rows: 1fr;
                }
            }
            @media screen and (max-width: 650px) {
                button {
                    margin: 0;
                    padding: 5px;
                    max-width: 100%;
                    word-break: break-word;
                    font-size: .7rem;
                }
                #tog{
                    max-width: 50%;
                    overflow: hidden;
                    margin: 0;
                    padding: 0px;
                }
                #toggle {
                    grid-area: 1 / 2 / span 1 / span 1;

                }
                #sidebar {
                    grid-area: 2 / 1 / span 1 / span 2;
                    max-height: 250px;
                }
                #buttons {
                    grid-template-rows: 1fr 1fr 1fr;
                    grid-template-columns: 1fr 1fr 1fr;
                }
                #buttonBox {
                    flex-direction: column;
                }            
                
                input[type="range"] {
                    writing-mode: bt-lr;
                    -webkit-appearance: slider-vertical;
                    transform: scale(1.5);
                    margin: 5px;
                }
                #close {
                    font-size: 1rem;
                }
            }            

            @media screen and (min-width: 800px) {
                #img {
                    width: 400px;
                    max-height: 400px;
                    justify-self: center;
                }
                img-f {
                    padding: 10px;
                }
            }
            #cancel:hover {
                background-color: var(--warn);
            }
            #cropBar {
                position: fixed;
                top: 10px;
                display: inline-flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
                max-width: 80%;
                overflow: hidden;
                margin: 0;
                padding: 0;
                width: 80%;
                z-index: 1000;
            }

        </style>
        <div id="cont">
            <div id="sidebar"><div id="buttons"></div></div>
            <div id="toggle">
                <span id="selected"></span>
                <input id="tog" type="range" min="0" max="200" value="100">
                <div id="buttonBox"><div id="crop">CROP</div><div id="submit">SUBMIT</div></div>
            </div>
            <div id="img">
                <img-f id="i" img="${this.image}"></img-f>
            </div>
            <div id="cropBox" class="hidden">
                <div id="cropBar"><button id="confirm">CONFIRM CROP</button><button id="cancel">CANCEL</button></div>
                <crop-img id="c"></crop-img>
            </div>
            <div id="close">X</div>
        </div>`
        this.shadow.querySelector('#close').addEventListener('click', () => this.remove())
        this.i = this.shadow.querySelector('#i')
        this.c = this.shadow.querySelector('#c')
        this.set()
        let side = this.shadow.querySelector('#buttons')
        let tog = this.shadow.querySelector('#tog')
        let sel = this.shadow.querySelector('#selected')
        this.shadow.querySelector('#cancel').addEventListener('click', () => {this.toggleClasses()})
        this.shadow.querySelector('#confirm').addEventListener('click', ()=>{
            let cropped = this.shadow.querySelector('crop-img')
            this.properties.crop = {
                x: Math.abs(cropped.x),
                y: Math.abs(cropped.y),
                w: Math.abs(cropped.w),
                h: Math.abs(cropped.h),
                s: parseInt(cropped.s)
            }
            this.submitCrop().then(result => {
                console.log(result)
                this.hasCropped = result.result
                console.log(this.hasCropped)
                this.properties.crop = null
                this.shadow.querySelector('img-f').setAttribute('img', this.hasCropped.src)
                this.toggleClasses()
                this.shadow.querySelector('crop-img').resetImage()
                this.shadow.querySelector('#close').addEventListener('click', () => {
                    window.app.setState({images: [...window.app.state.images].filter(img => {
                        if (img._id === this.hasCropped._id) {
                            console.log('changed crop')
                            return this.hasCropped
                        } else {
                            return img
                        }
                    })})
                    window.app.render()
                }, {once:true})

            }).catch(e => {
                this.shadow.querySelector('crop-img').warn(e, 2500)
            })
        })
        this.shadow.querySelector('#crop').addEventListener('click', (e) => {
            this.c.setAttribute('filter', this.currentStyle())
            this.c.setAttribute('image', this.crop)
            this.toggleClasses()
        })
        sel.innerHTML = this.selected
        Object.keys(this.properties).forEach(key => {
            let b = document.createElement('button')
            side.append(b)
            b.innerHTML = key
            b.addEventListener('click', this.select)
            if (key === this.selected) {
                b.classList.add('a')
            }
        })
        tog.value = this.properties[this.selected]
        tog.addEventListener('change', this.filter)
        this.shadow.querySelector('#submit').addEventListener('click', () => {
            if (this.hasCropped) {
                window.app.setState({images: [...window.app.state.images].filter(img => {
                    if (img._id === this.hasCropped._id) {
                        console.log('changed crop', img._id, img.src)
                        img.src = this.hasCropped.src
                        img.cropped = this.hasCropped.cropped
                        console.log('now should be', img.src)
                    }
                    return img
                })})
            }
            this.Answer()
        })
    }
    toggleClasses(){
        [this.shadow.querySelector('#toggle'),this.shadow.querySelector('#img'),this.shadow.querySelector('#sidebar'),this.shadow.querySelector('#cropBox')].forEach(z=>{if (z.classList.contains('hidden')){z.classList.remove('hidden')}else{z.classList.add('hidden')}})
    }
    submitCrop(){
        return new Promise((res, rej)=> {
            if (this.properties.crop) {
                let answer = {
                    _id: this._id,
                    crop: this.properties.crop
                }
                window.app.editImage(answer, true).then(result => {
                    return res(result)
                }).catch(e => {
                    return rej(e)
                })
            } else {
                return rej('NO CROP')
            }
        })
    }
    Answer(){
        console.log('answer')
        let opacity = this.properties.opacity
        if (opacity > 1) {
            opacity = 1
        }
        let answer = {
            _id: this._id,
            brightness: this.properties.brightness,
            contrast: this.properties.contrast,
            hue: this.properties.hue,
            grayscale: this.properties.grayscale,
            saturate: this.properties.saturate,
            invert: this.properties.invert,
            blur: this.properties.blur,
            sepia: this.properties.sepia,
            opacity: opacity,
            border: this.properties.border
        }
        window.app.editImage(answer)
    }
    connectedCallback(){
        if (this.hasAttribute(['image']) && this.getAttribute(['image']) !== 'null') {
            let a = JSON.parse(this.getAttribute(['image']))
            this.image = a.src ? a.src : null
            this.crop = a.original ? a.original : null;
            this._id = a._id ? a._id : null
            if (a.sepia) this.properties.sepia = a.sepia;
            if (a.opacity) this.properties.opacity = a.opacity;
            if (a.grayscale) this.properties.grayscale = a.grayscale;
            if (a.saturate) this.properties.saturate = a.saturate;
            if (a.hue) this.properties.hue = a.hue;
            if (a.blur) this.properties.blur = a.blur;
            if (a.border) this.properties.border = a.border;
            if (a.invert) this.properties.invert = a.invert;
            if (a.contrast) this.properties.contrast = a.contrast;
            if (a.brightness) this.properties.brightness = a.brightness;
            if (this._id && this.image) this.render()
        }
    }
    currentStyle(){
        return `filter: brightness(${this.properties.brightness}%) contrast(${this.properties.contrast}%) hue-rotate(${this.properties.hue}deg) grayscale(${this.properties.grayscale}%) blur(${this.properties.blur}px) sepia(${this.properties.sepia}%) invert(${this.properties.invert}%) saturate(${this.properties.saturate}%); border-radius: ${this.properties.border}px; opacity: ${this.properties.opacity > 1 ? 1 : this.properties.opacity};`
    }
    static get observedAttributes(){
        return ['image']
    }
    attributeChangedCallback(name, o, n){
        if (['image'].includes(name)) {
            let a = JSON.parse(n)
            if (a) {
                this.image = a.src ? a.src : ''
                this._id = a._id ? a._id : ''
                if (a.sepia) this.properties.sepia = a.sepia;
                if (a.opacity) this.properties.opacity = a.opacity;
                if (a.grayscale) this.properties.grayscale = a.grayscale;
                if (a.saturate) this.properties.saturate = a.saturate;
                if (a.hue) this.properties.hue = a.hue;
                if (a.blur) this.properties.blur = a.blur;
                if (a.border) this.properties.border = a.border;
                if (a.invert) this.properties.invert = a.invert;
                if (a.contrast) this.properties.contrast = a.contrast;
                if (a.brightness) this.properties.brightness = a.brightness;
                if (this.image && this._id) {this.render()} else {this.shadow.innerHTML = ''}
            } else {
                this.shadow.innerHTML = ''
            }
        }
    }
}
customElements.define('edit-image', EditImage)
class Images extends Component {
    constructor(){
        super()
        this.state = {
            images: []
        }
        this.clickhandler = (e) => {
            let s = 'imgbox'
            let t = e => e.classList.contains(s) ? true : false
            let elem = t(e.target) ? e.target : t(e.target.parentElement) ? e.target.parentElement : t(e.target.parentElement.parentElement) ? e.target.parentElement.parentElement : null
            console.log(elem)
            if (!elem || typeof elem !== 'object') return
            if (!elem.hasAttribute('trig')) elem.setAttribute('trig', '1')
            console.log(elem.getAttribute('trig'))
            if (elem.getAttribute('trig') === '1') { 
                if (!elem.classList.contains('popbox')) elem.classList.add('popbox') 
                if (elem.querySelector('#imginfo')?.classList?.contains('hidden')) elem.querySelector('#imginfo').classList.remove('hidden')
                elem.setAttribute('trig', '2')
            } else if (e.target.id === 'CIM') {                
                if (elem.classList.contains('popbox')) elem.classList.remove('popbox') 
                if (!elem.querySelector('#imginfo').classList.contains('hidden')) elem.querySelector('#imginfo').classList.add('hidden')
                elem.setAttribute('trig', '1')
            }
        }
    }
    componentDidUpdate(){
        if (this.state.images.length !== this.props.images.length) this.setState({images: this.props.images})
    }
    componentDidMount(){
        this.setState({images: this.props.images})
    }
    render(){
        let p = this.props.profile
        let z
        if (p && p.profilePic) z = p.profilePic
        let a = this.props.images ? [...this.props.images].filter(u => u.owner === this.props.un).map((image, key) => {
            if (!image) return ''
            return <div key={key} className="b1" id={image._id}><div className='imgbox' onClick={this.clickhandler}>
                <img-f style={{maxWidth: '80%'}} img={image.src} hue={image.hue} 
                    saturate={image.saturate} grayscale={image.grayscale} border={image.border} 
                    brightness={image.brightness} contrast={image.contrast} blur={image.blur} 
                invert={image.invert} opacity={image.opacity} sepia={image.sepia} shadow={'1px 2px 3px #00000050'}></img-f>
                <div id="imginfo" className="hidden">
                <div className="imgInfo"><p>Uploaded: {window.app.date(image.uploaded)}</p></div><button id="CIM">CLOSE IMAGE</button>
        <div className="buttonBar"><button id="PP" onClick={this.props.setProfile}>{p && z === image._id ? 'PROFILE PIC' : 'MAKE PROFILE PIC'}</button><button id="EI" onClick={this.props.editImage}>EDIT</button><button id="DI" onClick={this.props.delete}>DELETE</button></div>
                </div></div>
        </div>
        }) : null;
        return (
        <div className={'imagesCont' + (this.props.shown === true ? '' : ' hidden')}>
            <div id="imageGrid">
                {a.length > 0 ? a : <h5 style={{gridArea: '1 / 1 / span 1 / span 2'}}>YOUR IMAGES WILL APPEAR HERE</h5>}
            </div>
            <div className="newtodo" onClick={this.props.newImage}>New Image</div>
            <div className="lefttodo" onClick={this.props.close}>CLOSE</div>
        </div>
        )
    }
}
export default Images