import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
window.clean = array => {
  let well = []
  let oil = d => {
    if (!d || !d._id) return false
    if (!well) return true
    if (!well.includes(d._id)) {
      well.push(d._id)
      return true
    } else {
      return false
    }
  }
  return array.filter(oil)
}
window.compareObjects = (o, r) => {
  let a = Object.entries(o)
  let b = Object.entries(r)
  if (a.length !== b.length) return false
  for (let i = 0; i < a.length; i++) {
    if (a[i][0] !== b[i][0] || a[i][1] !== b[i][1]) return false
  }
  return true
}
window.reconnect = 0
window.checkws = function(){
    let sock = this.ws
    if (sock.readyState !== WebSocket.OPEN) {
        this.newSocket().catch(e => {
            console.log(e)
        })
    }
}
window.checkauth = function(){
  let sock = this.ws
  if (sock && sock.readyState === WebSocket.OPEN && (window.app.state.auth && window.app.state.auth !== 'undefined') && (window.app.state.userID && window.app.state.userID !== 'undefined')) sock.send(JSON.stringify({type: 'auth', data: {method: 'check', auth: window.app.state.auth, _id: window.app.state.userID, displayName: window.app.state.profile?.displayName ? window.app.state.profile.displayName : ''}}))
}
window.runSocket = (e) => {
  const app = window.app
  let payload
  if (!e.type) {
    payload = e.data.type
  } else {
    payload = e.type
  }
  switch(payload) {
    case 'auth': {
      if (e.error) {
        console.log(e.message)
        sessionStorage.removeItem('auth')
        let b = e.method === 'check' ? false : true
        console.log(b, e.method)
        app.setState({auth: 'undefined', authFail: b, showImages: false, showMessages: false, showProfile: false})
      } else if (e.data) {
        if (e.data.auth) {
          console.log('setting auth')
          app.setState({auth: e.data.auth, showProfile: true, showMessages: false})
          sessionStorage.setItem('auth', e.data.auth)
          if (!app.state.displayNames || app.state.displayNames.length < 1) app.getDictionary()
        }
        if (e.data._id) {
          console.log('setting userID')
          app.setState({userID: e.data._id})
          sessionStorage.setItem('userID', e.data._id)
        }
        if (e.data.images) {
          console.log('adding images')
          app.setState({images: window.clean([...app.state.images, ...e.data.images])})
        }
        if (e.data.posts) {
          console.log('adding posts')
          let p = [...app.state.posts] 
          let z
          if (typeof e.data.posts === 'object' && e.data.posts.length > 0) {
            z = [...e.data.posts]
          } else {
            z = []
          }
          if (z.length > 0) {
            app.setState({posts: window.clean([...p, ...z])})
          }
        }
        if (!e.data.profile) console.log(e.method)
        if (e.data.profile) {
          console.log('updating profile')
          clearTimeout(window._MPT)
          if (!e.data.profile.firstName) {
            console.log('make profile')
            app.makeProfile()
            app.setState({showProfile: false})
          }
          app.setState({profile: e.data.profile})
          sessionStorage.setItem('profile', JSON.stringify(app.state.profile))
        } else if (e.method === 'new') {
          app.setState({profile: {}})
          sessionStorage.setItem('profile', JSON.stringify({}))
          app.makeProfile()
          app.setState({showProfile: false})
        }
        if (e.data.friendList) {
          console.log('updating friendlist')
          app.setState({friendList: e.data.friendList})
          sessionStorage.setItem('friendList', JSON.stringify(app.state.friendList))
        }
        if (e.data.friendRequests) {
          console.log('updating friendrequests')
          app.setState({friendRequests: e.data.friendRequests})
          sessionStorage.setItem('friendRequests', JSON.stringify(app.state.friendRequests))
        }
        if (e.data.sentRequests) {
          console.log('updating sentrequests')
          app.setState({sentRequests: e.data.sentRequests})
          sessionStorage.setItem('sentRequests', JSON.stringify(app.state.sentRequests))
        }
      }
      break
    }
    case 'message': {
      console.log('NEW MESSAGE')
      if (e.data.from) {
        console.log(e.data)
        app.setState({messages: [...app.state.messages, e.data]})
        let a = document.querySelector('#e' + e.data._id)
        if (a) a.scrollIntoView({behavior: 'smooth'})
      } else {
        let message = e.data?.data
        if (message.from) app.setState({messages: [...app.state.messages, message]})
      }
      break
    }
    case 'event': {
      if (e.data?.post) {
        console.log('adding new post')
        app.setState({posts: [...app.state.posts, e.data.post]})
        if (e.data.post.owner !== app.state.userID) app.notify('post', 'hello')
      }
      if (e.data?.image) {
        let image = e.data.image
        let o = app.state.images.find(img => img._id === image._id)
        if (o) {
          console.log('updating image')
          app.setState({images: [...app.state.images].map(img => {if (img._id === image._id) {return image} else {return img}})})
        } else {
          console.log('adding new image')
          app.setState({images: [...app.state.images, image]})
        }
      }
      if (e.data?.profile) {
        console.log('updating profile')
        clearTimeout(window._MPT)
        app.setState({profile: e.data.profile})
        sessionStorage.setItem('profile', JSON.stringify(app.state.profile))
      }
      if (e.data?.friendList) {
        console.log('updating friends')
        app.setState({friendList: e.data.friendList})
        sessionStorage.setItem('friendList', JSON.stringify(app.state.friendList))
      }
      if (e.data?.friendRequests) {
        console.log('updating friend requests')
        app.setState({friendRequests: e.data.friendRequests})
        sessionStorage.setItem('friendRequests', JSON.stringify(app.state.friendRequests))
      }
      if (e.data?.sentRequests) {
        console.log('updating sent requests')
        app.setState({sentRequests: e.data.sentRequests})
        sessionStorage.setItem('sentRequests', JSON.stringify(app.state.sentRequests))
      }
      if (e.data?.chatlog) {
        console.log('updating chatlog')
        let x = e.data.chatlog
        let a = [...app.state.messages].filter(message => {
          let o = true
          for (let i = 0; i < x.length; i++){
            if (x[i]._id === message._id) {
              o = false
              i = x.length
            }
          }
          if (o) {
            return message
          } else {
            return o
          }
        })
        let sorted = [...a, ...x].sort((a,b) => a.time - b.time)
        app.setState({messages: sorted})
      }
      if (e.data?.connected) {
        console.log('updating connected users')
        app.setState({onlineUsers: e.data.connected})
      }
      if (e.data?.messages || e.data?.data?.messages) {
        if (e.data?.error || e.data?.data?.error) {console.log(e.data.message ? e.data.message : e.data.data.message ? e.data.data.message  : e.data)}
        let mess = [...e.data.messages]
        if ((!mess || mess.length < 1) && e.data?.data?.messages) {}
        console.log(`RECEIVED MESSAGE LIST(${mess.length}): ${JSON.stringify(mess)}`)
        let m = [...app.state.messages].filter((m) => {
          for (let i = 0; i < mess.length; i++) {
            if (mess[i]._id === m._id) {let rm = mess.splice(i, 1)}
          }
          return m
        })
        app.setState({messages: [...m, ...mess].sort((a,b) => a.time - b.time)})
      }
      break
    }
    default: {
      console.log(`INVALID TYPE: ${e}`)
    }
  }
}
window.newSocket = function(){
    return new Promise((res, rej) => {
      clearInterval(window.heartbeat)
      clearInterval(window.authbeat)
      try {
          window.ws = new WebSocket('wss://ws.yourroutines.com')  //ws://localhost:3256 ''
          window.ws.onopen = function(e){
            window.heartbeat = setInterval(() => { window.checkws()}, 1000)
            window.authbeat = setInterval(() => {window.checkauth()}, 30000)
          };
          window.ws.onmessage = function incoming(data) {
            let o
            try {
              o = JSON.parse(data)
            } catch {
              try {
                o = JSON.parse(data.data)
              } catch { o = null }
            }
            if (data.data?.type) {
              window.runSocket(data.data)
            } else if (o) {
              window.runSocket(o)
            } else {
              try {
                window.runSocket(JSON.parse(data.data))
              } catch {
                try {
                  window.runSocket(JSON.parse(data))
                } catch {
                  console.log(`NEW WS DATA FROM SERVER: ${data?.data} ${JSON.stringify(data) || data}`)
                  if (data?.data?.type) window.runSocket(data.data)
                }
              }
            }
          }
          return res(true)
      } catch {
          if (window.reconnect <= 10) {
              window.reconnect++
              console.log(`ATTEMPTING RECONNECT: ${window.reconnect}/10`)
              window.newSocket()
          } else {
              return rej('Error reloading websocket. must be an issue on the backend.')
          }
      }
    })
}
window.renderApp = () => {
  ReactDOM.render(<React.StrictMode><App source={IDM => { window.app = IDM }} /></React.StrictMode>, document.getElementById('root'));
}
window._PROF = () => {
  let o = sessionStorage.getItem('profile')
  if (!o || o === 'undefined') return {}
  try {
    let m = JSON.parse(o)
    return m
  } catch {
    return {}
  }
}
window._FRIENDS = () => {
  let o = sessionStorage.getItem('friendList')
  if (!o || o === 'undefined') return []
  try {
    let m = JSON.parse(o)
    return m
  } catch {
    return []
  }
}
window._FRIENDREQUESTS = () => {
  let o = sessionStorage.getItem('friendRequests')
  if (!o || o === 'undefined') return []
  try {
    let m = JSON.parse(o)
    return m
  } catch {
    return []
  }
}
window._SENTREQUESTS = () => {
  let o = sessionStorage.getItem('sentRequests')
  if (!o || o === 'undefined') return []
  try {
    let m = JSON.parse(o)
    return m
  } catch {
    return []
  }
}
window.renderApp()
window.newSocket().then(()=> {
  if (document.querySelector('load-circle')) document.querySelector('load-circle').remove()
}).catch(e => {
  console.log(`WEBSOCKET CONNECTION ERROR: ${e}`);
  if (document.querySelector('load-circle')) document.querySelector('load-circle').remove()
})