import React, {Component} from 'react';
import ReactDOM from 'react-dom'
import './App.css';
import Messenger from './components/Instant'
import Sign from './components/SignIn'
import Images from './components/Images'
import Nav from './components/Nav'
import NewImage from './components/NewImage'
import ProfileCreation from './components/ProfileCreate'
import Profile from './components/Profile'
import Search from './components/Search'
class App extends Component {
  state = {
    messages: [],
    images: [],
    auth: sessionStorage.getItem('auth'),
    userID: sessionStorage.getItem('userID'),
    profile: window._PROF(),
    friendList: window._FRIENDS(),
    friendRequests: window._FRIENDREQUESTS(),
    sentRequests: window._SENTREQUESTS(),
    feed: [],
    search: '',
    searchResult: [],
    searchList: [],
    showMessenger: true,
    showImages: false,
    showSearch: false,
    showProfile: false,
    recentUpload: null,
    authFail: false,
    posts: [],
    onlineUsers: [],
    displayNames: [],
    onlineDisp: [],
    messaging: 'mass'
  }
  signOut(){
    sessionStorage.clear()
    window.init = null
    window.ws.send(JSON.stringify({type: 'auth', data: {method: 'logout', auth: window.app.state.auth, userID: window.app.state.userID}}))
    if (window.chat) window.chat.setState({chats: []})
    window.app.setState({
      messages: [...window.app.state.messages].filter(mes => mes.to === 'mass'),
      images: [],
      auth: sessionStorage.getItem('auth'),
      userID: sessionStorage.getItem('userID'),
      profile: window._PROF(),
      feed: [],
      friendList: window._FRIENDS(),
      friendRequests: window._FRIENDREQUESTS(),
      sentRequests: window._SENTREQUESTS(),
      search: '',
      searchResult: [],
      searchList: [],
      showMessenger: true,
      showProfile: false,
      showImages: false,
      showSearch: false,
      recentUpload: null,
      authFail: false,
      posts: [],
      messaging: 'mass',
      viewing: {}
    })
  }
  api = 'https://api.yourroutines.com' //http://localhost:3255  
  date(date) {
    if (!date) {
        return ''
    } else {
        let dif = new Date() - new Date(date)
        let seconds = Math.floor(dif/1000)
        let minutes = Math.floor(seconds/60)
        let hours = Math.floor(minutes/60)
        return seconds <= 60 ? `${seconds}s ago` : minutes < 60 ? `${minutes} minutes ago` : hours < 24 ? `${hours} hours ago` : 'a while ago...'
    }
  }
  kick(){
    sessionStorage.clear()
    this.setState({auth: 'undefined', userID: 'undefined'})
  }
  validate = (a) => {
    return new Promise((res, rej) => {
      if (a.error !== false) {
        if (a.kick) this.kick()
        return rej(new Error(`ERROR: ${a?.message ? a.message : a ? a : 'no response text'}`))
      }
      return res(a)
    })
  }
  sendData = (page, data, auth) => {
    return new Promise((res, rej) => {
      if (auth && !this.state.auth) return rej('No Auth')
      if (!page || !data) return rej('No Page Requested')
      let authorization
      if (auth) {
        authorization = `Bearer ${this.state.auth}`
      }
      let that = new XMLHttpRequest()
      that.open('POST', this.api + page, true)
      if (authorization) {
        that.setRequestHeader('authorization', authorization)
      }
      that.setRequestHeader('Content-Type', 'Application/JSON')
      that.onabort = (e) => {
        return rej(e)
      }
      that.onerror = (e) => {
        return rej(e)
      }
      that.onreadystatechange = () => {
        if (that.readyState === 4) {
          try {
            return res(JSON.parse(that.responseText))
          } catch (e) {
            return rej(e)
          }
        }
      }
      that.send(JSON.stringify(data))
    })
  }
  sendMessage = (e) => {
    e.preventDefault()
    let form = e.target
    let message = form['message'].value
    if (!message) return form.querySelector('.warn').innerHTML = 'Make sure to include a message.'
    let image = form.parentElement.parentElement.querySelector('image-selector').selectedID
    console.log(image)
    try {
      window.ws.send(JSON.stringify({type: 'message', data: {auth: this.state.auth, from: this.state.userID, to: form['to'] ? form['to'].value : 'mass', body: message, attachments: image ? image : []}}))
      form.reset()
      form.parentElement.parentElement.querySelector('image-selector').setAttribute('selected', '')
      form.querySelector('#AAButton').innerHTML = 'ADD IMAGE'
      form.querySelector('.warn').innerHTML = ''
    } catch(e){
      console.log(e)
      form.querySelector('.warn').innerHTML = e
    }
  }
  signUp = (e) => {
    e.preventDefault()
    const form = e.target
    let p = form['password'].value
    let p2 = form['password2'].value
    if (p.length < 8) return form.querySelector('.warn').innerHTML = 'Password must be at least 8 characters'
    if (p !== p2) return form.querySelector('.warn').innerHTML = 'Passwords do not match'
    this.sendData('/signup', {email: form['identifier'].value, password: p}, false).then(result => this.validate(result)).then(result => {
      sessionStorage.clear()
      this.setState({userID: result._id, auth: result.auth,
        images: [],
        profile: {},
        feed: [],
        friendList: [],
        friendRequests: [],
        search: '',
        searchResult: [],
        showMessenger: true,
        showProfile: false,
        showImages: false,
        showSearch: false,
        recentUpload: null,
        authFail: false,
        posts: [],
        messaging: 'mass'})
      sessionStorage.setItem('userID', JSON.stringify(this.state.userID))
      sessionStorage.setItem('auth', JSON.stringify(this.state.auth))
      if (!this.state.displayNames || this.state.displayNames.length < 1) this.getDictionary()
    }).catch(e => {
      form.querySelector('.warn').innerHTML = e
    })
  }
  logIn = (e) => {
    e.preventDefault()
    const form = e.target
    const warn = form.querySelector('.warn')
    form.querySelector('button').innerHTML = '<load-circle></load-circle>'
    try {
      window.ws.send(JSON.stringify({type: "auth", data: {method: "new", email: form['identifier'].value, password: form['password'].value}}))
    } catch(e) {
      console.log(e)
      warn.innerHTML = 'ISSUE LOGGING YOU IN.'
    }
  }
  imageFind = (image) => {
    return new Promise((res, rej) => {
      this.sendData('/image', {image: image}, this.state.auth).then(result => this.validate(result)).then(result => {
      if (!result || !result.image) {return rej('NO IMAGE')}
      this.setState({images: window.clean([...this.state.images, result.image])})
      return res(result.image)
    }).catch(e => rej(e))
    })
  }
  newImage = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const form = e.target
    form.querySelector('button').innerHTML = '<load-circle></load-circle>'
    const warn = form.querySelector('.warn')
    const warning = (e) => {
      form.querySelector('button').innerHTML = 'UPLOAD'
      return warn.innerHTML = e
    }
    let filer = e.target['image'].files
    if (!filer[0]) return warning('NO FILE SELECTED')
    var oData = new FormData(form);
    oData.append('image', filer);
    form.reset()
    let that = new XMLHttpRequest()
    that.onabort = (e) => {
      console.log(e)
      return warning('UPLOAD TIMED OUT')
    }
    that.onerror = (e) => {
      console.log(e)
      return warning('SERVER CONNECTION ISSUE')
    }
    that.upload.onloadstart = (e) => {
      console.log('UPLOAD START')
    }
    that.upload.onerror = (e) => {
      console.log(e)
      return warning(`FAILED UPLOAD`)
    }
    that.upload.onprogress = (e) => {
      const {loaded, total} = e
      warn.innerHTML = `${Math.floor((loaded*100)/total)}% UPLOADED`
    }
    that.onreadystatechange = (e) => {
      if (that.readyState === 4) {
        let data = JSON.parse(that.responseText)
        if (data.error !== false) return warning(`ERROR: ${data.message ? data.message : data}`)
        this.setState({recentUpload: data.result, showImages: true, images: [...this.state.images, data.result]})
        this.removePop()
        let a = document.createElement('edit-image')
        a.setAttribute('image', JSON.stringify(this.state.recentUpload))
        document.getElementById('root').querySelector('.App').querySelector('#popupform').append(a)
      }
    }
    that.open('POST', this.api + '/newimage', true)
    that.setRequestHeader('authorization', 'Bearer ' + this.state.auth)
    that.send(oData)
  }
  newPImage = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const form = e.target
    form.querySelector('button').innerHTML = '<load-circle></load-circle>'
    form.querySelector('input').classList.add('hidden')
    const warn = form.querySelector('.warn')
    const warning = (e) => {
      form.querySelector('button').innerHTML = 'UPLOAD'
      form.querySelector('input').classList.remove('hidden')
      return warn.innerHTML = e
    }
    let filer = e.target['image'].files
    if (!filer[0]) return warning('NO FILE SELECTED')
    var oData = new FormData(form);
    oData.append('image', filer);
    form.reset()
    let that = new XMLHttpRequest()
    that.onabort = (e) => {
      console.log(e)
      return warning('UPLOAD TIMED OUT')
    }
    that.onerror = (e) => {
      console.log(e)
      return warning('SERVER CONNECTION ISSUE')
    }
    that.upload.onloadstart = (e) => {
      console.log('UPLOAD START')
    }
    that.upload.onerror = (e) => {
      console.log(e)
      return warning(`FAILED UPLOAD`)
    }
    that.upload.onprogress = (e) => {
      const {loaded, total} = e
      warn.innerHTML = `${Math.floor((loaded*100)/total)}% UPLOADED`
    }
    that.onreadystatechange = (e) => {
      if (that.readyState === 4) {
        let data = JSON.parse(that.responseText)
        if (data.error !== false) return warning(`ERROR: ${data.message ? data.message : data}`)
        this.setState({recentUpload: data.result, profile: data.profile, showImages: false, images: data.images})
        sessionStorage.setItem('profile', JSON.stringify(this.state.profile))
        form.parentElement.querySelector('img-f').setAttribute('img', data.result.src)
        form.querySelector('button').innerHTML = 'Change'
        form.querySelector('input').classList.remove('hidden')
      }
    }
    that.open('POST', this.api + '/newpimage', true)
    that.setRequestHeader('authorization', 'Bearer ' + this.state.auth)
    that.send(oData)
  }
  editImage(data, crop) {
    return new Promise((res, rej) => {
      if (crop) {
        data['userID'] = this.state.userID
        this.sendData('/editimage', data, this.state.auth).then(result => this.validate(result))
        .then(result => {
          this.setState({showImages: true, images: [...this.state.images].map(image => {if (image._id === result._id) {return result} else {return image}})})
          return res(result)
        }).catch(e => {return rej(e)})
      } else {
        this.sendData('/editimage', data, this.state.auth).then(result => this.validate(result))
        .then(() => {
          this.setState({recentUpload: null})
          this.getImages().then(result => {
            document.getElementById('root').querySelector('.App').querySelector('#popupform').querySelector('edit-image').remove()
            this.setState({images: result})
            return res(true)
          }).catch(e => {console.log(e); return rej(e)})
        }).catch(e => {console.log(e);  return rej(e)})
      }
    })
  }
  setProfilePic = (e) => {
    let id = e.target.parentElement.parentElement.parentElement.parentElement.id
    const elem = e.target
    elem.innerHTML = '<load-circle></load-circle>'
    this.sendData('/update-profile', {profile: {profilePic: id}}, this.state.auth).then(result => this.validate(result)).then(result => {
      console.log(result)
      elem.innerHTML = 'Profile Pic'
      this.setState({profile: result.profile})
      sessionStorage.setItem('profile', JSON.stringify(this.state.profile))
    }).catch(er => {
      console.log(er)
      this.signOut()
    })
  }
  getPosts(){
    clearTimeout(this.GPT)
    if (!this.state.profile.posts || !this.state.auth || this.state.auth === 'undefined') return this.GPT = setTimeout(() => this.getPosts(), 400)
    this.sendData('/posts', {posts: this.state.profile.posts}, this.state.auth).then(result => this.validate(result)).then(result => this.setState({posts: result.posts})).catch(e => console.log(e))
  }
  deleteImage = (e) => {
    const id = e.target.parentElement.parentElement.parentElement.parentElement.id
    if (!id) return console.log(e.target?.parentElement?.parentElement)
    if (!this.state.auth || !this.state.userID) return console.log('NO AUTH')
    fetch(this.api + '/image', {
      method: 'DELETE',
      body: JSON.stringify({
        _id: id,
        auth: this.state.auth,
        user: this.state.userID
      }),
      headers: {'Content-Type': 'Application/JSON'}
    }).then(result => result.json()).then(result => this.validate(result))
    .then(result => {
      console.log(result.deleted)
      this.setState({images: [...this.state.images].filter(image => image._id !== id)})
    }).catch(e => console.log(e))
  }
  getImages(){
    return new Promise((res, rej) => {
      if (!this.state.auth || this.state.auth === 'undefined') return rej('NO AUTH FOR IMAGES')
      fetch(this.api + '/images', {headers: {'authorization': `Bearer ${this.state.auth}`}}).then(result => result.json()).then(result => this.validate(result))
      .then(result => {if (window.imageSelect) {window.imageSelect.setAttribute('num', Math.random().toString())} return res(result.images)}).catch(e => {return rej(e)})
    })
  }
  imageEditForm(e){
    console.log('clicked')
    let id = e.target?.parentElement?.parentElement?.parentElement?.parentElement?.id
    console.log(id)
    if (id) {
      let cont = document.getElementById('root').querySelector('.App').querySelector('#popupform')
      let a = document.createElement('edit-image')
      let image = [...window.app.state.images].filter(im => {return im._id === id})[0]
      a.setAttribute('image', JSON.stringify(image ? image : null))
      cont.append(a)
    }
  }
  profilePhase1 = (e) => {
    e.preventDefault()
    const form = e.target
    const warn = form.querySelector('.warn')
    const warning = e => warn.innerHTML = e
    let first = form['firstName'].value
    let last = form['lastName'].value
    let display = form['displayName'].value
    if (!first) return warning('Please enter a first name.')
    if (!last) return warning('Please enter a last name.')
    if (!display) {
      form['displayName'].placeholder = form['firstName'].value ? 'USER' + Math.floor(Math.random()*100) + form['firstName'].value : ''
      return warning('Just choose a display name :)')
    }
    let bio = form['bio'] ? form['bio'].value : ''
    console.log(bio)
    this.sendData('/update-profile', {user: this.state.userID, firstName: first, lastName: last, displayName: display, bio: bio}, this.state.auth).then(result => {
      const profile = result.profile
      let that = this.state.profile ? {...this.state.profile} : {}
      if (profile.firstName) that.firstName = profile.firstName
      if (profile.lastName) that.lastName = profile.lastName
      if (profile.displayName) that.displayName = profile.displayName
      if (profile.bio) that.bio = profile.bio
      this.setState({profile: that, showProfile: true})
      sessionStorage.setItem('profile', JSON.stringify(this.state.profile))
      this.removePop()
    }).catch(e => {
      console.log(e)
      warning.innerHTML = 'Issue updating your info. Please try again.'
    })
  }
  uploadImage(){
    ReactDOM.render(<NewImage newImage={window.app.newImage} close={window.app.removePop}/>, document.getElementById('root').querySelector('.App').querySelector('#popupform'))
    window.app.setState({showImages: false})
  }
  makeProfile(){
    ReactDOM.render(<ProfileCreation profile={window.app.state.profile} profilePhase1={window.app.profilePhase1} addProfilePic={window.app.uploadImage} close={window.app.removePop}/>, document.getElementById('root').querySelector('.App').querySelector('#popupform'))
    window.app.setState({showImages: false, showMessenger: false})
  }
  loadProfile = (id) => {
    if (!id) return console.log('no id')
    fetch(this.api + '/profile/' + id).then(result => result.json()).then(result => this.validate(result)).then(result => {
      window.app.setState({showImages: false, showMessenger: false, viewing: result.profile, posts: [...this.state.posts, ...result.posts]})
    }).catch(e => {
      console.log(e)
    })
  }
  removePop = (c) => {
    try {
      ReactDOM.unmountComponentAtNode(document.querySelector('#root').querySelector('.App').querySelector('#popupform'))
    } catch {
      console.log('no pop')
    }
    if (c?.target?.id === 'closenewimage') window.app.setState({showImages: true})
  }
  closeImages = () => {
    window.app.setState({showImages: false})
  }
  openImages = () => {
    window.app.setState({showImages: true})
    this.removePop()
  }
  updateProfile(data){
    return new Promise((res, rej) => {
      this.sendData('/update-profile', data, this.state.auth).then(result => {
        this.setState({profile: result.profile})
        sessionStorage.setItem('profile', JSON.stringify(this.state.profile))
        return res(true)
      }).catch(e => {
        return res(e)
      })
    })
  }
  getDictionary = () => {
    if (this.state.auth && this.state.auth !== 'undefined') this.sendData('/dictionary', {}, this.state.auth).then(result => this.validate(result)).then(result => this.setState({displayNames: result.dictionary})).catch(e => console.log(e))
  }
  getProfile() {
    window.ws.send(JSON.stringify({type: "event", data: {profile: true, auth: this.state.auth, userID: this.state.userID}}))
  }
  getFriendlist() {
    window.ws.send(JSON.stringify({type: "event", data: {friends: true, auth: this.state.auth, userID: this.state.userID}}))
  }
  getFriendRequests() {
    window.ws.send(JSON.stringify({type: "event", data: {friendRequests: true, auth: this.state.auth, userID: this.state.userID}}))
  }
  getSentRequests() {
    window.ws.send(JSON.stringify({type: "event", data: {sentRequests: true, auth: this.state.auth, userID: this.state.userID}}))
  }
  render(){
    this.props.source(this)
    let p
    if (!window.start && this.state.auth && this.state.auth !== 'undefined' && this.state.userID && this.state.userID !== 'undefined') {
      setTimeout(() => window.checkauth(), 500)
      window.start = true      
    } else {
      window.start = true
    }
    if ((!this.state.profile || !this.state.profile.firstName) && this.state.auth && this.state.auth !== 'undefined' && !window.init) {
      window._MPT = setTimeout(() => {this.makeProfile()},1000)
      p = <div onClick={this.makeProfile}>MAKE PROFILE</div>
      window.init = true
    } else if (this.state.profile && this.state.userID && this.state.userID !== 'undefined' && this.state.auth && this.state.auth !== 'undefined' && !window.init) {
      clearTimeout(window._MPT)
      setTimeout(() => {window.app.setState({showProfile: true, showChat: false})},1)
      setTimeout(() => window.checkauth(), 500)
      setTimeout(() => {window.app.getDictionary()}, 1500)
      window.init = true
    }
    return(<div className="App">
      <Nav signOut={this.signOut} search={() => {window.app.setState({showSearch: !window.app.state.showSearch})}} openImages={this.openImages} auth={this.state.auth}></Nav>
      <Sign authFail={this.state.authFail} newUser={this.signUp} logIn={this.logIn} un={this.state.userID} auth={this.state.auth}></Sign>
      <Images un={this.state.userID} profile={this.state.profile} setProfile={this.setProfilePic} close={this.closeImages} shown={this.state.showImages} images={this.state.images} newImage={this.uploadImage} editImage={this.imageEditForm} delete={this.deleteImage}/>
      <Profile profilePhase1={this.profilePhase1} profile={this.state.profile} showProfile={this.state.showProfile}></Profile>
      <Profile profile={this.state.viewing} close={() => {window.app.setState({viewing: {}})}}/>
      <Search viewing={this.state.viewing} showSearch={(this.state.userID || this.state.userID !== 'undefined') ? this.state.showSearch : false} online={this.state.onlineUsers} searchList={this.state.displayNames} un={this.state.userID}></Search>
      <Messenger friendList={this.state.friendList ? this.state.friendList : []} friendRequests={this.state.friendRequests ? this.state.friendRequests : []} sentRequests={this.state.sentRequests ? this.state.sentRequests : []} source={(IDM) => window.chat = IDM} messaging={this.state.messaging} profile={this.state.profile} messages={this.state.messages} addImage={this.openImages} newMessage={this.sendMessage} auth={this.state.auth} un={this.state.profile.displayName ? this.state.profile.displayName : this.state.userID} level={this.state.showMessenger}></Messenger>
      {p ? p : ''}
      <div id="popupform"></div>
    </div>)
  }
}

export default App;